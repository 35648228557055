import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatNativeDateModule } from '@angular/material/core';

import { EventCoverComponent } from './components/event-cover/event-cover.component';
import { CheckSellerComponent } from './components/check-seller/check-seller.component';
import { AllEventsCarouselComponent } from './components/all-events-carousel/all-events-carousel.component';
import { AllOrganizersCarouselComponent } from './components/all-organizers-carousel/all-organizers-carousel.component';
import { AllPressArticlesCarouselComponent } from './components/all-press-articles-carousel/all-press-articles-carousel.component';
import { FeedbackCardComponent } from './components/carousel/feedback-card/feedback-card.component';
import { PartnerCardComponent } from './components/carousel/partner-card/partner-card.component';
import { EventCardComponent } from './components/event-card/event-card.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { EventInfoComponent } from './components/event-info/event-info.component';
import { RouterModule } from '@angular/router';
import { TicketSharingComponent } from './components/ticket-sharing/ticket-sharing.component';
import { PressCardComponent } from './components/carousel/press-card/press-card.component';
import { EmailValidationComponent } from './components/email-validation/email-validation.component';

import { FormStorageDirective } from './directives/form-storage.directive';
import { CarouselComponent } from './components/carousel/carousel.component';
import { LetDirective } from './directives/let.directive';
import { EventCustomMessageComponent } from './components/event-custom-message/event-custom-message.component';
import { ReelaxTicketsLogoComponent } from './components/reelax-tickets-logo/reelax-tickets-logo.component';
import { CarouselVerticalComponent } from './components/carousel-vertical/carousel-vertical.component';
import { EventsCarouselVerticalComponent } from './components/events-carousel-vertical/events-carousel-vertical.component';
import { FeedbacksCarouselVerticalComponent } from './components/feedbacks-carousel-vertical/feedbacks-carousel-vertical.component';
import { NumericFormFieldComponent } from './components/inputs/numeric-form-field/numeric-form-field.component';
import { TicketPriceComponent } from './components/ticket-price/ticket-price.component';
import { UploaderTinyPreviewComponent } from './components/uploader/uploader-tiny-preview/uploader-tiny-preview.component';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { SocialMediaPreviewComponent } from './components/social-media-preview/social-media-preview.component';
import { HelpButtonComponent } from './components/help-button/help-button.component';
import { TextFormFieldComponent } from './components/inputs/text-form-field/text-form-field.component';
import { SelectFormFieldComponent } from './components/inputs/select-form-field/select-form-field.component';
import { LoaderComponent } from './components/loader/loader.component';
import { TrackingClickDirective } from './directives/tracking-click.directive';
import { PaymentFormComponent } from './components/payment-form/payment-form.component';
import { ScrollDirective } from './directives/scroll.directive';
import { TextareaFormFieldComponent } from './components/inputs/textarea-form-field/textarea-form-field.component';
import { CheckboxFormFieldComponent } from './components/inputs/checkbox-form-field/checkbox-form-field.component';
import { RadiogroupFormFieldComponent } from './components/inputs/radiogroup-form-field/radiogroup-form-field.component';
import { DialogSnackComponent } from './components/dialog-snack/dialog-snack.component';
import { SalePurchaseComponent } from './components/sale-purchase/sale-purchase.component';
import { HelpDialogComponent } from './components/help-dialog/help-dialog.component';
import { FaqContentComponent } from './components/faq-content/faq-content.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { HelpDirective } from './directives/help.directive';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { ClipboardDirective } from './directives/clipboard.directive';
import { CardComponent } from './components/card/card.component';
import { TrimDirective } from './directives/trim.directive';
import { AutocompleteFormFieldComponent } from './components/inputs/autocomplete-form-field/autocomplete-form-field.component';
import { AutocompleteAsyncFormFieldComponent } from './components/inputs/autocomplete-async-form-field/autocomplete-async-form-field.component';
import { SeatDetailsComponent } from './components/seat-details/seat-details.component';
import { DateRangeSelectComponent } from './components/inputs/date-range-select/date-range-select.component';
import { CustomBuyerFieldsComponent } from './components/custom-buyer-fields/custom-buyer-fields.component';
import { CustomCheckboxFieldsComponent } from './components/custom-checkbox-fields/custom-checkbox-fields.component';
import { WaitlistSummaryComponent } from './components/waitlist-summary/waitlist-summary.component';
import { CurrencyFormFieldComponent } from './components/inputs/currency-form-field/currency-form-field.component';
import { CalendarIconComponent } from './icons/calendar-icon/calendar-icon.component';
import { WaitlistpurchaseErrorComponent } from './components/waitlistpurchase-error/waitlistpurchase-error.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { StatusButtonComponent } from './components/inputs/status-button/status-button.component';
import { ConfirmAccountDeletionDialogComponent } from './components/check-seller/confirm-account-deletion-dialog/confirm-account-deletion-dialog.component';
import { PasswordPolicyDialogComponent } from './components/password-policy-dialog/password-policy-dialog.component';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { DateTimeFormFieldComponent } from './components/inputs/date-time-form-field/date-time-form-field.component';
import { UppercaseSpace4Directive } from './directives/uppercase-space4.directive';
import { CarouselScrollComponent } from './components/carousel-scroll/carousel-scroll.component';
import { DragScrollDirective } from './directives/drag-scroll.directive';
import { CategoryDescriptionComponent } from './components/category-description/category-description.component';
import { DebugScrollDirective } from './directives/debug-scroll.directive';
import { WaitlistGaugeComponent } from './components/waitlist-gauge/waitlist-gauge.component';
import { SavingIndicatorComponent } from './components/saving-indicator/saving-indicator.component';
import { CustomTicketFieldsComponent } from './components/custom-ticket-fields/custom-ticket-fields.component';
import { DateFormFieldComponent } from './components/inputs/date-form-field/date-form-field.component';
import { DialogBasicComponent } from './components/dialog-basic/dialog-basic.component';
import { ErrorControlsComponent } from './components/error-controls/error-controls.component';
import { SellerPayoutManagerComponent } from './components/seller-payout-manager/seller-payout-manager.component';

const inputs = [
];

const directives = [
  FormStorageDirective,
  LetDirective,
  TrackingClickDirective,
  ScrollDirective,
  HelpDirective,
  ClipboardDirective,
  TrimDirective,
  DragScrollDirective,
  DebugScrollDirective,
  UppercaseSpace4Directive,
];

const components = [
  EventCoverComponent,
  CheckSellerComponent,
  FeedbackCardComponent,
  PartnerCardComponent,
  AllEventsCarouselComponent,
  AllOrganizersCarouselComponent,
  AllPressArticlesCarouselComponent,
  UploaderComponent,
  UploaderTinyPreviewComponent,
  EventCardComponent,
  LoginFormComponent,
  EventInfoComponent,
  TicketSharingComponent,
  PressCardComponent,
  CarouselComponent,
  EventCustomMessageComponent,
  ReelaxTicketsLogoComponent,
  CarouselVerticalComponent,
  EventsCarouselVerticalComponent,
  FeedbacksCarouselVerticalComponent,
  TicketPriceComponent,
  SocialMediaPreviewComponent,
  HelpButtonComponent,
  LoaderComponent,
  PaymentFormComponent,
  DialogSnackComponent,
  SalePurchaseComponent,
  HelpDialogComponent,
  FaqContentComponent,
  ContactFormComponent,
  CardComponent,
  EmailValidationComponent,
  SeatDetailsComponent,
  CustomBuyerFieldsComponent,
  CustomCheckboxFieldsComponent,
  WaitlistSummaryComponent,
  WaitlistpurchaseErrorComponent,
  BackButtonComponent,
  ConfirmAccountDeletionDialogComponent,
  PasswordPolicyDialogComponent,
  PasswordStrengthComponent,
  CarouselScrollComponent,
  CategoryDescriptionComponent,
  WaitlistGaugeComponent,
  SavingIndicatorComponent,
  CustomTicketFieldsComponent,
  DialogBasicComponent,
  ErrorControlsComponent,
  SellerPayoutManagerComponent,
  LanguageSelectorComponent,
  TextareaFormFieldComponent,
  DateFormFieldComponent,
  TextFormFieldComponent,
  NumericFormFieldComponent,
  SelectFormFieldComponent,
  CheckboxFormFieldComponent,
  RadiogroupFormFieldComponent,
  AutocompleteFormFieldComponent,
  AutocompleteAsyncFormFieldComponent,
  DateTimeFormFieldComponent,
  DateRangeSelectComponent,
  CurrencyFormFieldComponent,
  StatusButtonComponent,
  CalendarIconComponent,
];


@NgModule({
  declarations: [
    ...inputs,
  ],
  imports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatNativeDateModule,
    NgxDropzoneModule,
    RouterModule,
    TranslocoModule,
    TranslocoLocaleModule,
    ...inputs,
    ...directives,
    ...components,
  ],
  exports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatNativeDateModule,
    TranslocoModule,
    TranslocoLocaleModule,
    ...inputs,
    ...directives,
    ...components,
  ],
  providers: [
    { provide: TRANSLOCO_SCOPE, useValue: '' },
  ],
})
export class SharedModule { }
