import { Directive, HostListener, Input, inject } from '@angular/core';
import { TrackingService } from '../../services';
import { MatomoEvent } from '../../models';

@Directive({
  selector: '[appTrackClick]',
  standalone: true,
})
export class TrackingClickDirective {
  private trackingService = inject(TrackingService);

  /**
   * @param {MatomoEvent} event - The matomo event information
   */
  @Input('appTrackClick') event: MatomoEvent;

  @HostListener('click')
  onClick() {
    this.trackingService.addEvent(this.event);
  }

}
