import { IFaq } from '../../app/models';

/**
 * En admin :

Quand on séléctionne des questions grace aux checkbox (éditer un événement),
chaque fragment est sauvegardé dans le champ selectedFragments de l'objet EventCustomFaq

Ex : [acheteur_achat-automatique-jour-event, acheteur_event, acheteur_evenement-indisponible, acheteur]

Quand on modifie une question (cliquer sur une question sur l'interface admin),
la nouvelle question est sauvegardé en bdd dans le champ changesFromOriginal de la facon suivante :
{"acheteur_alertes-achat-automatique":
  {"answer": "\n Réponse à la question \n ", "question": " Comment on fait ... ?"}
}
C'est un objet où les clés sont les fragments à modifier et les valeurs sont la nouvelle valeur de cet objet dans la faq


Quand on ajoute une question via le "+", elle est sauvegardée dans customQuestions.
Même structure qu'un objet de la faq AVEC EN PLUS un attribut parent: string pour pouvoir les positioner dans l'arbre

Coté user:

La faq dans le dialog est presque pareil. Il y a un filtre qui n'affiche que éléments dans selectedFragments.
Les éléments de customQuestions sont ajoutés dans l'objet faq récupéré du front avec un fragment que je génère.
Au lieu d'afficher la question ou la réponse de la faq directement, on affiche plutot customOrOriginalData(key: string
qui regarde si pour cette valeur il n'y avait pas un élément de changesFromOriginal qui correspond.

Pour les ids, on réserve :
 - les 500 premiers pour les questions finales
 - 500 à 1000 pour les boutons intermédiaires
 - le reste pour les questions customs (id aléatoire)
*/
export const faq: IFaq = {
  root: {
    id: 500,
    fragment: 'root',
    question: 'Pour faciliter l’arrivée des secours, pouvez-vous nous en dire un peu plus sur votre profil ? 👨‍🚒',
    children: ['buyer', 'seller', 'organizer'],
  },
  // Depth = 1
  buyer: {
    id: 501,
    name: 'Acheteur',
    fragment: 'acheteur',
    question: 'Ma question concerne ...',
    children: ['buyer_event', 'buyingTicket', 'waitingList', 'autoPurchase', 'myAccountBuyer', 'order', 'buyer_aboutReelax'],
  },
  seller: {
    id: 502,
    name: 'Vendeur',
    fragment: 'vendeur',
    question: 'Ma question concerne ...',
    children: ['seller_event', 'putOnSale', 'myAccountSeller', 'friendSale', 'payement',  'cancelSelling', 'seller_aboutReelax'],
  },
  organizer: {
    id: 503,
    name: 'Organisateur',
    fragment: 'organisateur',
    question: 'Ma question concerne ...',
    children: ['settingUpMarketPlace', 'organisateur_aboutReelax', 'accounting', 'data'],
  },
  // Depth = 2
  seller_aboutReelax: {
    id: 504,
    name: 'Le fonctionnement de Reelax Tickets',
    fragment: 'vendeur_fontionnement_reelax',
    children: ['seller_whyReelax'],
  },
  putOnSale: {
    id: 505,
    name: 'Une mise en vente',
    fragment: 'vendeur_mise-en-vente',
    question: 'Et plus précisément ...',
    children: ['sellPrice', 'categoryOrBarcode', 'cashless', 'privateSale', 'saleProcess'],
  },
  cancelSelling: {
    id: 506,
    name: 'L\'annulation d\'une vente',
    fragment: 'vendeur_annulation-vente',
    children: ['howToWithdraw', 'ticketNotResale', 'resaleOnMyOwn', 'putOnSaleBySomeone'],
  },
  myAccountSeller: {
    id: 507,
    name: 'Mon compte',
    fragment: 'vendeur_mon-compte',
    children: ['cantValidIKYC', 'whyIDCard', 'cantFindConfirmationMail', 'forgotPassword', 'noMoreAccess', 'modifyEmail', 'deleteAccount'],
  },
  friendSale: {
    id: 508,
    name: 'Une vente à un(e) ami(e)',
    fragment: 'vendeur_vente-ami',
    children: ['howToFriendSale', 'modifyTicketNameSeller'],
  },
  payement: {
    id: 509,
    name: 'Un paiement',
    fragment: 'vendeur_paiement',
    children: ['whenReceiveMoney'],
  },
  seller_event: {
    id: 510,
    name: 'Un événement',
    fragment: 'vendeur_event',
    children: ['dontFindEventSeller'],
  },
  buyer_aboutReelax: {
    id: 511,
    name: 'Le fonctionnement de Reelax Tickets',
    fragment: 'acheteur_fontionnement_reelax',
    children: ['buyer_whyReelax', 'differentPage'],
  },
  buyer_event: {
    id: 512,
    name: 'Un événement',
    fragment: 'acheteur_event',
    children: ['dontFindEventBuyer', 'canceledEvent'],
  },
  buyingTicket: {
    id: 513,
    name: 'L\'achat d\'un billet',
    fragment: 'acheteur_achat-billet',
    children: ['howToBuy', 'searchForATichet', 'whatIsReserved', 'howManyFees', 'howToBuyToFriend'],
  },
  waitingList: {
    id: 514,
    name: 'La liste d\'attente',
    fragment: 'acheteur_liste-attente-btn',
    children: ['waitingListWorking', 'modifyWaitingList', 'losingPlaceWaitingList', 'knowingMyPosition', 'stepbackInJauge', 'multipleTickets', 'quantityLimit', 'dontReceiveAlerts', 'desinscriptionWaitingList', 'availableTicketsNoAlert'],
  },
  autoPurchase: {
    id: 515,
    name: 'L\'achat automatique',
    fragment: 'acheteur_achat-auto',
    children: ['whyActivateAutomaticPurchase', 'activateDesactivateAutoPurchase', 'cantActivateAutoPurchase', 'modifyCreditCard', 'purchaseOptionFunctioning', 'autoPurchaseEventDate', 'dontReceiveAlertSinceAutoPurchase', 'SMSAlertAutomaticPurchase', 'deleteCardAutomaticPurchase'],
  },
  myAccountBuyer: {
    id: 516,
    name: 'Mon compte',
    fragment: 'acheteur_mon-compte',
    children: ['modifyEmailBuyer', 'forgotPasswordBuyer', 'noMoreAccessBuyer', 'deleteAccountBuyer'],
  },
  order: {
    id: 517,
    name: 'Ma commande',
    fragment: 'acheteur_ma-commande',
    children: ['modifyTicketNameBuyer', 'pickupTicket', 'invoiceAfterBuying', 'emailMistakeInPurchase', 'dontReceiveTicket', 'canICancelSale'],
  },
  settingUpMarketPlace: {
    id: 518,
    name: 'La mise en place d\'une bourse aux billets',
    fragment: 'organisateur_mise-en-place-bob',
    children: ['softwareCompatibility', 'priceSettingUp', 'howSettingUp', 'timeSettingUp', 'reelaxConsultant', 'canIRefundBuyers', 'presenceReelax', 'whoTakesCareSAV'],
  },
  organisateur_aboutReelax: {
    id: 519,
    name: 'Le fonctionnement de Reelax Tickets',
    fragment: 'organisateur_fontionnement_reelax',
    children: ['organisateur_whyReelax', 'howSecure', 'whyDoingThat', 'whereAreWe'],
  },
  accounting: {
    id: 520,
    name: 'La comptabilité',
    fragment: 'organisateur_compatibilite',
    children: ['softwareCompatibility', 'interferenceDashboard', 'reelaxInTicketingSystem'],
  },
  data: {
    id: 521,
    name: 'Mes données',
    fragment: 'organisateur_donnees',
    children: ['integrabilityData', 'whoOwnsData', 'myCgv', 'reelaxProvider'],
  },
  // Depth = 3+
  seller_whyReelax: {
    id: 35,
    fragment: 'vendeur_pourquoi-reelax',
    question: 'Pourquoi Reelax Tickets est la plateforme de revente la plus sécurisée pour acheter et vendre vos billets d\'occasions ?',
    score: 0,
    answer: 'Nous utilisons <strong>le plus haut protocole de sécurité</strong> pour vous protéger de la fraude aux billets. Concrètement, nous sommes connectés aux bases de données des billetteries pour vérifier si les billets mis en vente sur notre plateforme sont valides. Ensuite, dès qu’un billet est acheté sur notre plateforme, nous créons instantanément un <strong>nouveau billet</strong> avec <strong>votre nom</strong>. ',
    children: [],
  },
  sellPrice: {
    id: 522,
    name: 'Le prix de vente',
    fragment: 'vendeur_prix-vente',
    children: ['canIChoosePrice', 'priceOfSelling', 'whyPriceDifference', 'howChangePrice'],
  },
  canIChoosePrice: {
    id: 21,
    fragment: 'vendeur_choix-prix',
    question: 'Est-ce que je peux définir librement mon prix de vente ?',
    score: 1,
    answer: `
      La fixation du prix de vente des billets n’est pas libre et est régie par deux conditions :
      <br>
      - La politique tarifaire de l’organisateur. C’est lui qui fixe les montants minimum et maximum auxquels les billets peuvent être revendus ;
      <br>
      - La législation en vigueur dans le pays de l’événement. En France par exemple, lorsque l'événement est subventionné ou aidé par l’État, une mairie, un département ou une région, la revente est possible à la condition que le prix du billet ne soit pas supérieur à celui affiché.
      `,
    children: [],
  },
  priceOfSelling: {
    id: 22,
    fragment: 'vendeur_cout-vente',
    question: 'Combien coûte la mise en vente de mon billet ?',
    score: 1,
    answer: `
      Lors de votre première vente, <b>un unique frais de vérification d’identité</b> peut être déduit de votre virement. Il couvre les charges liées à la vérification de votre document, exigée par les régulations européennes (AML4 et PSD2) pour garantir la sécurité des transactions.
      <br><br>
      Ce frais n’est <b>prélevé qu’une seule fois</b> : une fois votre compte vérifié, vous n’aurez plus à le payer pour vos futures ventes sur Reelax Tickets. 🎉
      <br><br>
      Si votre billet n’est pas vendu, aucun frais ne sera prélevé.
      <br><br>
      Aucuns autres frais supplémentaires ne seront prélevés sur les services de Reelax Tickets.
      <br><br>
      Tous les prix sont affichés T.T.C. (Toutes Taxes Comprises) sur les services de Reelax Tickets.
      `,
    children: [],
    related: ['whenReceiveMoney'],
  },
  whyPriceDifference: {
    id: 25,
    fragment: 'vendeur_frais-de-service',
    question: 'Pourquoi mon billet n\'est pas au même prix que celui que j\'ai indiqué ?',
    score: 1,
    answer: `
      Lorsque vous vendez un billet sur Reelax Tickets, il vous faut indiquer le montant que vous voulez recevoir sur votre compte bancaire. Nous vous indiquons alors le prix auquel votre billet sera disponible à l’achat.
      <br>
      <br>
      Ces deux prix diffèrent, car nous ajoutons des <strong>frais bancaires</strong> ainsi que nos <strong>frais de service</strong> à la charge de l’acheteur·se pour garantir la validité du billet acheté et maximiser vos chances de revendre vos billets. 
      `,
    children: [],
  },
  howChangePrice: {
    id: 34,
    fragment: 'vendeur_modifier-prix',
    question: 'Comment faire pour modifier le prix de mon billet ?',
    score: 0,
    answer: `
      Depuis l’onglet <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mes Billets</a>,
      accédez à votre billet mis en vente via la section <strong>Billet(s) en vente</strong>, puis cliquez sur <strong>Modifier le prix</strong>. Vous pouvez changer le prix de vente de votre billet si l’organisateur de l’événement le permet ! Une fois votre prix choisi, cliquez sur le bouton <strong>Valider</strong>.
      <img src="assets/img/faq/frFR/vente/18-1-comment-faire-pour-modifier-le-prix-de-mon-billet.png" class="faq-screenshots" />
      La valeur de gauche est le montant que vous recevrez et celle de droite est ce que devra payer l’acheteur·se. Une fois votre prix choisi, cliquez sur le bouton <strong>Valider</strong>.
      <img src="assets/img/faq/frFR/vente/18-2-comment-faire-pour-modifier-le-prix-de-mon-billet.png" class="faq-screenshots" />
      `,
    children: [],
  },
  categoryOrBarcode: {
    id: 523,
    name: 'La catégorie ou le code-barres de mon billet',
    fragment: 'vendeur_categorie_code_barres',
    children: ['cantPutOnSale', 'dontFindBarcode', 'dontFindCategory', 'culturePassResalable', 'sellAlreadyUsed'],
  },
  cashless: {
    id: 524,
    name: 'Le cashless préchargé',
    fragment: 'vendeur_cashless',
    children: ['linkCashless'],
  },
  privateSale: {
    id: 525,
    name: 'Mon billet est en vente privée',
    fragment: 'vendeur_vente-prive',
    children: ['ticketNotVisible'],
  },
  saleProcess: {
    id: 526,
    name: 'Le déroulement d\'une vente',
    fragment: 'vendeur_deroulement-vente',
    children: ['howToSell', 'increaseChanceToSell', 'canIResellReelaxTicket', 'sendTicketToBuyer'],
  },
  cantPutOnSale: {
    id: 29,
    fragment: 'vendeur_mise-en-vente-impossible',
    question: 'Pourquoi je n’arrive pas à mettre en vente mon billet ?',
    score: 0,
    answer: `
      Il peut y avoir plusieurs raisons pour lesquelles votre billet ne peut être mis en vente.
      <br>
      <br>
      Le plus souvent, il s'agit d'une erreur dans la saisie du code-barres (le code-barres commence généralement par un des numéros suivants : 66, 69, 1, 02, 002, 99, 43...).
      <br>
      Pensez bien à cliquer sur le point information <span class="material-icons" translate="no">info</span> pour obtenir des exemples de code-barres !
      <br>
      Nous vous recommandons de charger votre billet pour éviter les erreurs. De plus, certains billets cachent un numéro dans le code-barres qui n’est pas visible sur le billet. 
      <img src="assets/img/faq/frFR/vente/13-pourquoi-je-n-arrive-pas-a-mettre-en-vente-mon-billet.png" class="faq-screenshots" />
      Cependant, il se peut que certaines catégories ne soient pas autorisées à la revente ou bien autorisées uniquement lorsque l'événement affiche complet.
      <br>
      <br>
      Bien entendu, si votre billet est une invitation ou a déjà été remboursé auprès de l'organisateur, vous ne pourrez pas le revendre.
      <br>
      <br>
      Si la mise en vente de votre billet ne fonctionne pas, vous pouvez cliquer sur le bouton <strong>Demande d'aide</strong>.
      Nous recevrons alors votre billet et pourrons vous guider !
      `,
    children: [],
    related: ['howToFriendSale', 'howToSell', 'canIResellReelaxTicket'],
  },
  howToSell: {
    id: 16,
    fragment: 'vendeur_comment-vendre',
    question: 'Comment mettre en vente mon billet ?',
    score: 1,
    answer: `
      1) Sélectionnez l’événement pour lequel vous souhaitez vendre un billet depuis l'onglet 
      <a href="/evenements" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Événements</a>.
      <br>
      <br>
      2) Cliquez sur <strong>Vendre un billet</strong>.
      <img src="assets/img/faq/frFR/vente/1-1-comment-vendre-mon-billet.png" class="faq-screenshots" />
      3) Indiquez la catégorie de votre billet.
      <img src="assets/img/faq/frFR/vente/1-2-comment-vendre-mon-billet.png" class="faq-screenshots" />
      4) Chargez votre billet ou renseignez manuellement votre nom, votre prénom, ainsi que 
      le code-barres de votre billet
      (cliquez sur le point information <span class="material-icons" translate="no">info</span> 
      si vous ne trouvez pas votre code-barres).
      <img src="assets/img/faq/frFR/vente/1-3-comment-vendre-mon-billet.png" class="faq-screenshots" />
      <strong>⚠️ Certains billets cachent un numéro dans le code-barres qui n’est pas affiché sur le billet. Nous vous recommandons d’utiliser la reconnaissance automatique pour éviter les erreurs !</strong>
      <br>
      <br>
      5) Déterminez et validez le prix de votre billet. 
      <br>
      Vous pourrez toujours modifier le prix plus tard, si vous le souhaitez. 
      <img src="assets/img/faq/frFR/vente/1-4-comment-vendre-mon-billet.png" class="faq-screenshots" />
      6) <a href="/connexion" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Connectez-vous</a>,
      ou si vous n’avez pas encore de compte 
      <a href="/connexion?register=true" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Inscrivez-vous</a>
      en confirmant votre e-mail, puis renseignez vos informations personnelles ou celle de 
      la personne qui va recevoir l'argent si ce n'est pas vous.
      <img src="assets/img/faq/frFR/vente/1-5-comment-vendre-mon-billet.png" class="faq-screenshots" />
      7) Renseignez votre carte d'identité et votre compte bancaire ou les informations de la 
      personne qui va recevoir l'argent (cette étape peut être réalisée plus tard si vous le 
      souhaitez) puis validez pour terminer la mise en vente de votre billet en cliquant sur 
      <strong>Mettre en vente publique</strong>. 
      <br>
      <br>
      8) Votre billet est maintenant en vente sur la page de l’événement ou dans <a href="/faq/vendeur#vendeur_billet-liste-attente" class="link --primary">nos listes d’attente privées</a> ! 🎉 
      <br>Afin de multiplier vos chances de vendre votre billet, partagez le lien de votre annonce 
      de vente sur vos réseaux sociaux ;)
      <br>
      <br>
      Une fois la mise en vente confirmée, vous recevrez un e-mail de confirmation vous indiquant le récapitulatif de votre annonce et un rappel du prix de vente du billet.
      <br>
      <br>
      Dès lors que votre billet est en vente, <strong>il peut être acheté à tout moment</strong> sans qu’une quelconque confirmation ne vous soit demandée. Vous recevrez directement une notification par e-mail indiquant que votre billet a bien été vendu.
      <br>
      <br>
      Pour rappel, Reelax Tickets ne sauvegarde aucun document d’identité dans son système. Chacun d’entre eux est immédiatement envoyé à MANGOPAY, qui se charge d’assurer les vérifications nécessaires en toute sécurité. 
      `
    ,
    children: [],
    related: ['howChangePrice', 'ticketNotVisible', 'whenReceiveMoney'],
  },
  increaseChanceToSell: {
    id: 28,
    fragment: 'vendeur_augmenter-chances-vente',
    question: 'Comment augmenter les chances de vendre mon billet ?',
    score: 0,
    answer: `
      Tout d’abord, il faut savoir que la majorité des billets se revendent dans les deux dernières semaines avant l’événement.
      <br>
      <br>
      Ceci dit, voici deux astuces pour maximiser vos chances de revendre votre billet :
      <br>
      <br>
      1) En vous rendant sur <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mes Billets</a>,
      si l’organisateur a autorisé la vente à un prix inférieur au prix d’achat, vous pouvez modifier le prix de vente du billet en cliquant sur
      <span class="fw-700">Modifier le prix</span> depuis <span class="fw-700">Billet(s) en vente</span>.
      <br>
      <br>
      2) Partagez le lien de votre annonce sur vos réseaux sociaux en vous rendant sur <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mes Billets</a>
      (voir capture d’écran ci-dessous) !
      <br>
      Pour obtenir le lien, cliquez sur le bouton <span class="fw-700">Partager mon billet</span>.
      <br>
      <span class="fw-700">Cette astuce multiplie en moyenne par 3 vos chances de revendre votre billet !</span>
      <img src="assets/img/faq/frFR/vente/12-comment-augmenter-les-chances-de-vendre-mon-billet.png" class="faq-screenshots" />
      3) Enfin, votre billet reste valide tant qu’il n’a pas été vendu, vous pourrez donc vous rendre à l’événement avec le billet ou le donner à un·e proche.
      <br>
      <br>
      Cependant, un achat de dernière minute n’est pas impossible, donc si vous décidez de vous rendre à l’événement ou de donner votre billet,
      <span class="fw-700">vous devez absolument retirer votre billet de la vente depuis 
      <a href="/mon-compte/mes-billets#Vente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mes Billets</a>
      en vente au risque de vous faire refuser l’accès à l’événement !</span>
      <br>
      <span class="fw-700">En effet, votre billet sera désactivé dès l’instant où il sera vendu et il ne vous permettra donc plus d’accéder à l’événement !</span>
      `,
    children: [],
    related: ['howChangePrice', 'ticketNotResale', 'resaleOnMyOwn'],
  },
  dontFindBarcode: {
    id: 37,
    fragment: 'vendeur_trouver-code-barres',
    question: 'Je ne trouve pas mon numéro de code-barres quand j’essaye de le vendre. Que faire ?',
    score: 0,
    answer: `
      Ne vous embêtez pas à chercher votre code-barres, on s’en occupe pour vous ! Pour cela, il vous suffit de glisser / déposer votre billet dans l’encart dédié ou de le prendre en photo si vous utilisez un smartphone.
      <br>
      <br>
      <img src="assets/img/faq/frFR/vente/37-detection-code-barres.png" class="faq-screenshots" />
      Si la reconnaissance automatique ne trouve pas de code-barres, vous pouvez tenter de le renseigner manuellement via le bouton <strong>Saisie manuelle</strong>. Ce numéro se trouve généralement en dessous du code-barres ou bien à côté de l’intitulé “Numéro de billet”. Le code-barres commence généralement par un des numéros suivants : 66, 69, 1, 02, 002, 99, 43… Il ne faut pas le confondre avec les numéros de commande (qui commencent parfois par la lettre T).
      <br>
      <br>
      Si cela ne fonctionne toujours pas, vous pouvez cliquer sur le bouton <strong>Demande d'aide</strong>. Nous recevrons alors votre billet et pourrons vous guider !
      `,
    children: [],
    related: ['howToSell', 'cantPutOnSale'],
  },
  linkCashless: {
    id: 33,
    fragment: 'vendeur_recuperer-cashless',
    question: 'Comment faire si j’ai lié mon billet à un compte cashless pour l’événement ?',
    score: 0,
    answer: `
    Votre argent ne sera <span class="fw-700">pas transféré au détenteur·rice du nouveau billet !</span>
    <br>
    <br>
    Après l’événement, l’organisateur de l’événement mettra à disposition des spectateurs·rices un module vous permettant de récupérer l’argent lié à votre compte cashless en renseignant les informations de votre billet.
    <br>
    <br>
    <strong>Cette action ne sera pas automatique</strong>, nous vous recommandons donc de consulter régulièrement les réseaux sociaux et/ou le site Internet de l’organisateur après l’événement pour retrouver cette information. 
    `,
    children: [],
  },
  dontFindCategory: {
    id: 38,
    fragment: 'vendeur_categorie-indisponible',
    question: 'Je ne trouve pas ma catégorie dans la liste. Que faire ?',
    score: 0,
    answer: `
      Il se peut que certaines catégories ne soient pas autorisées à la revente (tarifs réduits, CE, Pass Culture…). 
      <br>
      <br>
      Si un message d'erreur indique "La revente n'est pas ouverte pour les billets de cette catégorie", cliquez sur le bouton <strong>Envoyer au support</strong> afin de nous transmettre votre billet. Si votre billet est revendable, nous reviendrons vers vous.
      <img src="assets/img/faq/frFR/vente/38-trouve-pas-categorie.png" class="faq-screenshots" />
      `,
    children: [],
    related: ['howToSell', 'cantPutOnSale'],
  },
  culturePassResalable: {
    id: 68,
    fragment: 'vendeur_pass-culture',
    question: 'Est-ce que je peux mettre en vente mon billet acheté avec le pass culture ?',
    score: 0,
    answer: `
      Malheureusement, nous ne sommes pas autorisés à permettre la revente des billets achetés via le pass culture.
      `,
    children: [],
  },
  sellAlreadyUsed: {
    id: 69,
    fragment: 'vendeur_diviser-pass',
    question: 'Puis-je mettre en vente un pass déjà utilisé ?',
    score: 0,
    answer: `
    Si votre billet a déjà été scanné, vous ne pourrez plus le mettre en vente sur notre plateforme, et ce, même si le billet est encore valide pour les jours suivants. En effet, les billetteries ne nous permettent en aucun cas d’accepter les reventes de billets déjà utilisés.
    <br><br>
    Si vous souhaitez accéder à l’événement un jour en particulier et non durant tous les jours comme indiqués sur votre pass. Vous pouvez vous inscrire sur la liste d'attente, via la page de l'événement dédiée sur Reelax Tickets et tenter d’obtenir un billet pour le jour souhaité.
    Ensuite, une fois que vous aurez obtenu votre nouveau billet, vous pourrez mettre en vente votre premier pass.
    `,
    children: [],
  },
  ticketNotVisible: {
    id: 59,
    fragment: 'vendeur_billet-liste-attente',
    question: 'Pourquoi mon billet n\'est pas visible par tout le monde ?',
    score: 0,
    answer: `
      Votre billet peut être réservé temporairement (24h maximum) pour les utilisateurs·rices inscrits·es sur la liste d’attente. Si votre billet n’est pas vendu après cette période, il sera alors accessible sur la plateforme et tous·tes les utilisateurs·rices pourront l’acheter.
      <br>
      <br>
      Vous pouvez trouver le lien de partage pour vos réseaux sociaux, depuis votre espace 
      <a href="/mon-compte/mes-billets#Vente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mes Billets</a> 
      dans <strong>Billet(s) en vente</strong> ou dans l’e-mail de confirmation de mise en vente. 
      Pour retrouver cet e-mail plus facilement, sachez qu’il a pour objet : <strong>Votre billet a bien été mis en vente.</strong>
      <br>
      <br>
      Vous pouvez vérifier à tout moment que votre billet est bien en vente depuis votre page <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mes Billets</span></a> via l'onglet <span class="fw-700">Billet(s) en vente.
      <img src="assets/img/faq/frFR/vente/3-pourquoi-mon-billet-n-est-pas-visible-par-tout-le-monde.png" class="faq-screenshots" />
      `,
    children: [],
    related: ['howToFriendSale', 'whenReceiveMoney', 'increaseChanceToSell'],
  },
  sendTicketToBuyer: {
    id: 27,
    fragment: 'vendeur_envoi-acheteur',
    question: 'Dois-je envoyer mon billet à l’acheteur·se ?',
    score: 1,
    answer: `
      Non, vous n'avez rien à faire !
      <br>
      <br>
      En effet, lors de la vente de votre billet sur Reelax Tickets, votre ancien billet est invalidé et <strong>l'acheteur reçoit automatiquement un nouveau billet</strong> à son nom.
      <br>
      <br>
      Vous pouvez vous débarrasser de votre ancien billet puisqu’une fois le billet vendu, le code-barres a été désactivé. 
      `,
    children: [],
    related: ['howToFriendSale'],
  },
  canIResellReelaxTicket: {
    id: 31,
    fragment: 'vendeur_revendre-billet-reelax',
    question: 'Puis-je revendre mon billet acheté sur Reelax Tickets ?',
    score: 0,
    answer: `
      <strong>Oui !</strong>
      <br>
      Vous pouvez utiliser le bouton <strong>Revendre ce billet</strong>, disponible sur la page 
      <a href="/mon-compte/mes-billets#Achat" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mes Billets</a>,
      dans la section <strong>Billets achetés</strong>. 
      <br>
      <br>
      Attention, pour des raisons de sécurité, nous pouvons limiter le nombre de reventes par billet. Si la mise en vente n'aboutit pas, cliquez sur le bouton <strong>Demande d'aide</strong>. Nous recevrons alors votre billet et pourrons vous guider !
      <br>
      <br>
      Le paiement de votre vente pourra se faire directement sur la carte bancaire utilisée lors de l’achat.
      `,
    children: [],
    related: ['howToSell'],
  },
  howToWithdraw: {
    id: 32,
    fragment: 'vendeur_annuler-vente',
    question: 'Comment retirer de la vente mon billet ?',
    score: 0,
    answer: `
      Vous pouvez annuler la mise en vente de votre billet depuis votre espace personnel via 
      <a href="/mon-compte/mes-billets#Vente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mes Billets</a>,
      à condition que le billet concerné soit ni vendu, ni réservé. 
      <br>
      <br>
      Après avoir sélectionné le billet que vous souhaitez annuler, cliquez sur <strong>Retirer de la vente</strong>, puis cliquez sur <strong>Confirmer</strong>. 
      <img src="assets/img/faq/frFR/vente/16-1-comment-retirer-de-la-vente-mon-billet.png" class="faq-screenshots" />
      <img src="assets/img/faq/frFR/vente/16-2-comment-retirer-de-la-vente-mon-billet.png" class="faq-screenshots" />
      Pour rappel, Reelax Tickets n’est pas responsable de la vente de votre billet et ne peut annuler une vente auprès d’un·e acheteur·se.
      `,
    children: [],
  },
  ticketNotResale: {
    id: 19,
    fragment: 'vendeur_non-vendu',
    question: 'Si mon billet n’est pas vendu, puis-je me rendre au concert avec ce billet ?',
    score: 1,
    answer: `
      Oui, votre billet reste valable s’il n’a pas été vendu.
      <br>
      <br>
      Si vous décidez de vous y rendre, vous devez <strong>absolument retirer votre annonce</strong> de vente en vous rendant sur l’onglet
      <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mes Billets</a>
      dans <span class="fw-700">Billet(s) en vente</span> afin d’éviter une mauvaise surprise !
      <br>
      <br>
      En effet, si votre billet est acheté sur notre plateforme, votre code-barres sera invalidé et vous ne pourrez plus accéder à l’événement.
      `,
    children: [],
    related: ['howToWithdraw'],
  },
  resaleOnMyOwn: {
    id: 24,
    fragment: 'vendeur_vendu-ailleurs',
    question: 'Comment faire si j’ai finalement vendu mon billet par mes propres moyens ?',
    score: 0,
    answer: `
      Dans le cas où vous trouveriez un·e acheteur·se par vos propres moyens, il vous faudra <span class="fw-700">retirer votre billet de la vente</span> depuis la page
      <a href="/mon-compte/mes-billets#Vente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mes Billets</a>
      pour éviter que la personne ayant acheté votre billet soit refusée à l'entrée de l'événement.
      <br>
      <br>
      En effet, si ce même billet est acheté sur notre plateforme, votre code-barres sera invalidé. Ainsi, la personne ayant acheté votre billet en dehors de la plateforme se verra refuser l'entrée !
      <br>
      <br>
      <strong>Pour rappel, il est formellement interdit de revendre un billet qui a déjà été revendu sur notre plateforme ! Il est de votre devoir de vérifier et d’annuler votre mise en vente avant d’essayer de vendre votre billet par un autre biais que celui de notre plateforme.</strong>
      `,
    children: [],
    related: ['howToWithdraw', 'howToFriendSale'],
  },
  putOnSaleBySomeone: {
    id: 85,
    fragment: 'vendeur_mis-en-vente-par-autrui',
    question: 'Que faire si mon billet a été mis en vente sans mon autorisation ?',
    score: 0,
    answer: `
      Selon la billetterie où vous avez acheté votre billet, si quelqu'un a mis en vente votre billet avec un email différent du votre, vous avez pu recevoir un email "Votre billet vient d'être mis en vente sur Reelax Tickets".
      <br>
      <br>
      Cet email mentionne une partie du nom et prénom de la personne responsable. Si vous la connaissez, nous vous recommandons de la contacter directement pour résoudre la situation.
      <br>
      <br>
      En cas de vol ou si vous ne reconnaissez pas la personne en question, nous vous invitons à
      <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>nous contacter</a>.
      <br>
      Nous pourrons alors intervenir pour bloquer la vente.
      `,
    children: [],
  },
  cantValidIKYC: {
    id: 30,
    fragment: 'vendeur_aide-identite',
    question: 'Je n’arrive pas à faire valider mon identité. Que faire ?',
    score: 0,
    answer: `
      Pour vérifier votre identité rapidement, voici les astuces indispensables :
      <br>
      - ✅ Prendre une <b>photo</b> du document (recto + verso),
      <br>
      - ✅ Soumettre un <b>document valide</b>, avec les <b>4 bords visibles</b> et en <b>couleur</b>,
      <br>
      - ✅ Respecter le format <b>PNG</b> ou <b>JPEG</b>,
      <br>
      - ✅ Les documents acceptés sont : la <b>carte d’identité</b>, le <b>passeport</b> et le <b>permis de conduire</b>.
      <br>
      <br>
     Certains problèmes sont récurrents lors de la vérification : 
      <br>
      - ❌ Les <b>scans</b> ne sont <b>pas acceptés</b>,
      <br>
      - ❌ La photo transmise est <b>floue</b> ou ne contient pas l’intégralité du document,
      <br>
      - ❌ Le <b>nom renseigné ne concorde pas</b> avec votre nom de naissance,
      <br>
      - ❌ Le document d’identité n’appartient pas à une personne majeure.
      <br>
      <br>
      Si vos documents sont refusés, vous recevrez un mail avec le motif de refus.
      <br>
      Si vous êtes mineur·e, vous pouvez renseigner une pièce d’identité d’un·e proche en pensant à changer les <a href="/mon-compte/informations-personnelles" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Informations personnelles</a> de votre compte. 
      <br>
      <br>
      Pour rappel, Reelax Tickets ne sauvegarde aucun document d’identité dans son système. Chacun d’entre eux est immédiatement envoyé à MANGOPAY, qui se charge d’assurer les vérifications nécessaires en toute sécurité.
      `,
    children: [],
  },
  whyIDCard: {
    id: 39,
    fragment: 'vendeur_identite',
    question: 'Pourquoi dois-je envoyer une pièce d’identité pour la vente ? ',
    score: 0,
    answer: `
      Soyez rassuré·e, Reelax Tickets est la plateforme la plus sécurisée du marché de la revente de billets entre particuliers. C’est pour cette raison que nous nouons des partenariats avec des organisateurs afin de permettre à tous.tes de revendre leurs billets, en toute sécurité. 
      <br>
      <br>
      Concernant la pièce d’identité, conformément aux dernières régulations européennes (AML4 et PSD2) visant à protéger les consommateurs·rices à réduire le blanchiment d'argent, notre prestataire de paiement MangoPay a besoin de vérifier l'identité de nos vendeurs·ses. Sans cette vérification, notre prestataire de paiement ne nous permet pas d'envoyer l'argent aux vendeurs·ses. Sachez que nous ne conservons aucun document : ils sont directement transmis à MangoPay où ils sont traités en toute sécurité.
      <br>
      <br>
      Si vous avez acheté votre billet sur Reelax Tickets, cette vérification n'est pas nécessaire. Le paiement peut se faire directement sur la carte bancaire utilisée à l’achat.
      `,
    children: [],
  },
  cantFindConfirmationMail: {
    id: 36,
    fragment: 'vendeur_mail-confirmation',
    question: 'Je ne retrouve pas le mail de confirmation de compte. Que faire ?',
    score: 0,
    answer: `
      Si vous ne trouvez pas l’e-mail de confirmation (avec pour objet <strong>Confirmez votre adresse mail</strong>), nous vous conseillons de vérifier si votre boîte mail n’a pas redirigé cet e-mail dans votre section Spams (ou dans l'onglet Promotions pour Gmail).
      <br>
      Dans ce cas, veillez à indiquer que Reelax Tickets n’est pas du spam s’il vous plaît ! 
      <br>
      En effet, nous pouvons être amenés à vous envoyer des e-mails importants pour vous envoyer vos billets ou vous notifier de la vente de votre billet.
      <br>
      <br>
      Certaines adresses mails peuvent recevoir le mail de confirmation avec un différé, dans ce cas, nous vous conseillons de patienter. Cependant, si Si vous rencontrez des difficultés pour valider votre compte, contactez-nous via la page <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Contact</a>. 
      `,
    children: [],
  },
  forgotPassword: {
    id: 40,
    fragment: 'vendeur_mot-de-passe-oublie',
    question: 'J’ai oublié mon mot de passe. Que faire ?',
    score: 0,
    answer: `
      En cas de perte ou d’oubli de mot de passe, rendez-vous sur la page de <a href="/connexion" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Connexion</span></a> et cliquez sur le lien <span class="fw-700">Mot de passe oublié ?</span>
      qui vous permettra de créer un nouveau mot de passe en conservant l'e-mail utilisé lors de la création de votre compte.
      <br>
      Ensuite, quand vous cliquerez sur le bouton <span class="fw-700">Confirmer</span>, nous vous enverrons par e-mail un lien pour créer un nouveau mot de passe.
      `,
    children: [],
  },
  forgotPasswordBuyer: {
    id: 81,
    fragment: 'acheteur_mot-de-passe-oublie',
    question: 'J’ai oublié mon mot de passe. Que faire ?',
    score: 0,
    answer: `
      En cas de perte ou d’oubli de mot de passe, rendez-vous sur la page de <a href="/connexion" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Connexion</span></a> et cliquez sur le lien <span class="fw-700">Mot de passe oublié ?</span>
      qui vous permettra de créer un nouveau mot de passe en conservant l'e-mail utilisé lors de la création de votre compte.
      <br>
      Ensuite, quand vous cliquerez sur le bouton <span class="fw-700">Confirmer</span>, nous vous enverrons par e-mail un lien pour créer un nouveau mot de passe.
      `,
    children: [],
  },
  modifyEmail: {
    id: 70,
    fragment: 'vendeur_modifier-mail',
    question: 'Comment modifier l\'adresse mail de mon compte ?',
    score: 0,
    answer: `
    Si vous souhaitez modifier l’adresse mail qui est reliée à votre compte Reelax Tickets, vous pouvez nous <a href="/contact" target="_blank" class="link --primary">contacter</a> en nous indiquant votre nouvelle adresse afin que nous puissions effectuer le changement.
    `,
    children: [],
  },
  modifyEmailBuyer: {
    id: 72,
    fragment: 'acheteur_modifier-mail',
    question: 'Comment modifier l\'adresse mail de mon compte ?',
    score: 0,
    answer: `
    Si vous souhaitez modifier l’adresse mail qui est reliée à votre compte Reelax Tickets, vous pouvez nous <a href="/contact" target="_blank" class="link --primary">contacter</a> en nous indiquant votre nouvelle adresse afin que nous puissions effectuer le changement.
    `,
    children: [],
  },
  deleteAccount: {
    id: 79,
    fragment: 'vendeur_supprimer-compte',
    question: 'Je souhaite supprimer mon compte. Que faire ?',
    score: 0,
    answer: `
    Pour des raisons de sécurité, la suppression de votre compte ne peut pas être effectuée directement depuis votre espace Mon compte.
    <br>
    Si vous souhaitez supprimer votre compte, veuillez nous contacter depuis la page <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>nous contacter</a>. Notre équipe vérifiera votre demande et procédera à la suppression de votre compte.
    `,
    children: [],
    related: ['modifyEmail'],
  },
  deleteAccountBuyer: {
    id: 80,
    fragment: 'acheteur_supprimer-compte',
    question: 'Je souhaite supprimer mon compte. Que faire ?',
    score: 0,
    answer: `
    Pour des raisons de sécurité, la suppression de votre compte ne peut pas être effectuée directement depuis votre espace Mon compte.
    <br>
    Si vous souhaitez supprimer votre compte, veuillez nous contacter depuis la page <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>nous contacter</a>. Notre équipe vérifiera votre demande et procédera à la suppression de votre compte.
    `,
    children: [],
    related: ['modifyEmailBuyer'],
  },
  noMoreAccess: {
    id: 82,
    fragment: 'vendeur_plus-acces',
    question: 'Je n\'ai plus accès à mon compte. Que faire ?',
    score: 0,
    answer: `
    Si vous n'arrivez plus à accéder à votre compte, il est possible que celui-ci ait été verrouillé en raison d'un trop grand nombre de tentatives de connexion. Dans ce cas, vous devriez avoir reçu un email "Votre compte Reelax Tickets est verrouillé" contenant un lien pour déverrouiller votre compte.
    <br>
    <br>
    Si vous n'avez pas reçu de mail, essayez de changer votre mot de passe. Une fonctionnalité <strong>Mot de passe oublié ?</strong> est disponible sur la page
    <a href="/connexion" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Connexion</a>.
    <br>
    <br>
    Si le problème persiste, il est possible que votre adresse mail ait été bloquée, dans ce cas vous pouvez nous contacter via la page
    <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Contact</a>
    afin que nous vous débloquions.
    `,
    children: [],
    related: ['modifyEmail', 'forgotPassword'],
  },
  noMoreAccessBuyer: {
    id: 83,
    fragment: 'acheteur_plus-acces',
    question: 'Je n’ai plus accès à mon compte. Que faire ?',
    score: 0,
    answer: `
    Si vous n'arrivez plus à accéder à votre compte, il est possible que celui-ci ait été verrouillé en raison d'un trop grand nombre de tentatives de connexion. Dans ce cas, vous devriez avoir reçu un email "Votre compte Reelax Tickets est verrouillé" contenant un lien pour déverrouiller votre compte.
    <br>
    <br>
    Si vous n'avez pas reçu de mail, essayez de changer votre mot de passe. Une fonctionnalité <strong>Mot de passe oublié ?</strong> est disponible sur la page
    <a href="/connexion" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Connexion</a>.
    <br>
    <br>
    Si le problème persiste, il est possible que votre adresse mail ait été bloquée, dans ce cas vous pouvez nous contacter via la page
    <a href="/contact" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Contact</a>
    afin que nous vous débloquions.
    `,
    children: [],
    related: ['modifyEmailBuyer', 'forgotPasswordBuyer'],
  },
  howToFriendSale: {
    id: 23,
    fragment: 'vendeur_vente-privee',
    question: 'Comment vendre un billet à un(e) ami(e) ?',
    score: 0,
    answer: `Lors de la mise en vente du billet, il faut cocher la case <span class="fw-700">Activer la revente privée pour un(e) ami(e) uniquement</span> à l'étape 2 (en dessous du choix de prix). À la fin de la mise en vente, il faudra ensuite lui partager le lien du billet pour qu'il/elle puisse l'acheter.
      <img src="assets/img/faq/frFR/vente/8-1-comment-vendre-un-billet-a-un-e-ami-e.png" class="faq-screenshots" />
      Si vous trouvez un acheteur.se après la mise en vente de votre billet, vous pouvez récupérer un lien privé depuis votre Compte dans l’onglet 
      <a href="/mon-compte/mes-billets#Vente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mes Billets</a>.
      Il vous suffit d’aller sur le billet en question, puis de cliquer sur <span class="fw-700">Mettre en vente à un(e) ami(e) uniquement</span>. Avec cette fonctionnalité, votre billet ne sera plus en vente publique. 
      <img src="assets/img/faq/frFR/vente/8-2-comment-vendre-un-billet-a-un-e-ami-e.png" class="faq-screenshots" />
      Puis cliquer sur <strong>Partager mon billet</strong> pour obtenir le lien privé à partager. 
      `,
    children: [],
    related: ['increaseChanceToSell', 'howChangePrice'],
  },
  modifyTicketNameBuyer: {
    id: 12,
    fragment: 'acheteur_modifier-nom-billet',
    question: 'Comment modifier le nom inscrit sur mon billet ?',
    score: 0,
    answer: `Vous pouvez utiliser la fonctionnalité de revente à un ami en 
      rachetant votre propre billet via un second compte Reelax Tickets.
      `,
    children: [],
    related: ['howToFriendSale', 'dontReceiveAlerts'],
  },
  modifyTicketNameSeller: {
    id: 74,
    fragment: 'vendeur_modifier-nom-billet',
    question: 'Comment modifier le nom inscrit sur mon billet ?',
    score: 0,
    answer: `Vous pouvez utiliser la fonctionnalité de revente à un ami en 
      rachetant votre propre billet via un second compte Reelax Tickets.
      `,
    children: [],
    related: ['howToFriendSale', 'dontReceiveAlerts'],
  },
  whenReceiveMoney: {
    id: 20,
    fragment: 'vendeur_delai-paiement',
    question: 'Quand est-ce que je reçois mon argent ?',
    score: 1,
    answer: `
      Le délai de paiement vous sera <span class="fw-700">communiqué avant la confirmation de la mise en vente</span>.
      <br>
      <br>
      Pour que nous puissions vous envoyer votre virement, votre <span class="fw-700">IBAN</span> et votre <span class="fw-700">pièce d'identité</span> doivent être renseignés sur <a href="/mon-compte/compte-bancaire" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mes Paiements</a>. Le virement peut être effectué seulement lorsque votre pièce d’identité aura été validée par notre prestataire de paiement (Mangopay). En attendant, votre argent sera stocké dans un coffre-fort électronique. Ce coffre-fort pourra uniquement être déverrouillé avec la validation de votre pièce d’identité.
      <br><br>
      Si vous avez acheté votre billet sur Reelax Tickets, le paiement pourra se faire sur la carte bancaire utilisée, sans nécessité de renseigner votre IBAN ni votre pièce d'identité.
      <br><br>
      En fonction des événements et des types de billets, le paiement peut être effectué sous <span class="fw-700">5 jours ouvrés après la vente</span>, ou bien sous <span class="fw-700">8 jours ouvrés une fois l'événement passé.</span> Vous pouvez suivre votre paiement, directement depuis votre <a href="/mon-compte/compte-bancaire" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Compte</a>. 
      <br><br>
      Le libellé du virement sur votre compte bancaire sera au nom de Mangopay, Mgp*Reelax Tick ou reelax tick.
      `,
    children: [],
    related: ['cantValidIKYC'],
  },
  dontFindEventBuyer: {
    id: 26,
    fragment: 'acheteur_evenement-indisponible',
    question: 'Pourquoi je ne trouve pas l’événement dans la liste ?',
    score: 1,
    answer: `
      Afin de garantir un niveau de sécurité maximal, la revente est <strong>uniquement disponible pour nos événements partenaires</strong>.
      <br><br>
      Si l'événement ne figure pas dans la liste des <a href="/evenements" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Événements</span></a>, n'hésitez pas à nous envoyer un message de <a href="/contact?subject=missingEvent" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Contact</a> pour nous dire quel est l'événement pour lequel vous souhaitez acheter ou vendre votre billet.
      <br>
      Vous pouvez également envoyer un message à l'organisateur pour lui faire part de votre requête.
      `,
    children: [],
  },
  dontFindEventSeller: {
    id: 73,
    fragment: 'vendeur_evenement-indisponible',
    question: 'Pourquoi je ne trouve pas l’événement dans la liste ?',
    score: 1,
    answer: `
      Afin de garantir un niveau de sécurité maximal, la revente est <strong>uniquement disponible pour nos événements partenaires</strong>.
      <br><br>
      Si l'événement ne figure pas dans la liste des <a href="/evenements" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Événements</span></a>, n'hésitez pas à nous envoyer un message de <a href="/contact?subject=missingEvent" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Contact</a> pour nous dire quel est l'événement pour lequel vous souhaitez acheter ou vendre votre billet.
      <br>
      Vous pouvez également envoyer un message à l'organisateur pour lui faire part de votre requête.
      `,
    children: [],
  },
  canceledEvent: {
    id: 66,
    fragment: 'acheteur_evenement-annule-reporte',
    question: 'Que se passe-t-il si l’événement est annulé ou reporté ?',
    score: 0,
    answer: `
    En cas de report ou d’annulation de l’événement, vous aurez la possibilité de percevoir un remboursement via l’organisateur de l’événement ou directement via Reelax Tickets. <strong>Les modalités de remboursement vous seront précisées avant la confirmation de votre commande.</strong>      <br>
    En dehors de l’annulation ou le report de l’événement, conformément à l'article L.121-28 du Code de la Consommation, les achats de Billets sur Reelax Tickets ne peuvent faire l'objet d'un droit de rétractation. Le billet n’est ni échangeable, ni remboursable.
    <br>
    Néanmoins, si votre billet ne vous intéresse plus, vous pouvez le revendre facilement sur notre site Internet.
    `,
    children: [],
    related: ['howToSell', 'canICancelSale', 'activateDesactivateAutoPurchase'],
  },
  howToBuy: {
    id: 0,
    question: 'Comment acheter un billet ?',
    fragment: 'acheteur_comment-acheter',
    score: 1,
    answer: `
      1) Rendez-vous sur la page <a href="/evenements" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Événements</a>
      <br>
      <br>
      2) Cliquez sur l'événement de votre choix.
      <br>
      <br>
      3) Cliquez sur le bouton <span class="fw-700">Acheter un billet</span>.
      <img src="assets/img/faq/frFR/achat/1-1-comment-acheter-un-billet.png" class="faq-screenshots" />
      4) Cliquez sur la catégorie de billet qui vous intéresse.
      <img src="assets/img/faq/frFR/achat/1-2-comment-acheter-un-billet.png" class="faq-screenshots" />
      5) Sélectionnez le billet qui vous intéresse et cliquez sur le bouton 
      <span class="fw-700">Acheter</span>.
      <img src="assets/img/faq/frFR/achat/1-3-comment-acheter-un-billet.png" class="faq-screenshots" />
      6) Si vous souhaitez retrouver le détail du prix vous pouvez cliquer sur le 
      point d’information 
      <span class="material-icons" translate="no">info</span>. 
      Puis, vous pouvez cliquer sur <strong>Suivant</strong>.
      <img src="assets/img/faq/frFR/achat/1-4-comment-acheter-un-billet.png" class="faq-screenshots" />
      7) Remplissez les champs avec les informations de l'acheteur·euse et 
      du porteur·euse du billet, puis cliquez sur <span class="fw-700">Suivant</span>.
      <img src="assets/img/faq/frFR/achat/1-5-comment-acheter-un-billet.png" class="faq-screenshots" />
      8) Renseignez votre carte bancaire et attendez jusqu’à valider le paiement 3D 
      Secure de votre banque qui s'affiche automatiquement sur la page. Vous allez, 
      ensuite, automatiquement revenir sur la page Reelax Tickets. 
      <br>
      <br>
      9) Vous pourrez alors télécharger votre billet sur la page de confirmation 
      d'achat ou bien directement dans le mail envoyé.
      `,
    children: [],
    related: ['dontFindEventBuyer', 'waitingListWorking', 'knowingMyPosition'],
  },
  searchForATichet: {
    id: 78,
    question: 'Je cherche un billet. Que faire ?',
    fragment: 'acheteur_cherche-billet',
    score: 1,
    answer: `
      Sur la page de l’événement en question, vous pouvez cliquer sur <strong>Acheter un billet</strong>, puis sélectionner <strong>Rejoindre la liste d'attente</strong>.
      Ainsi, vous serez averti(e) si de nouveaux billets sont mis en vente.
      <br>
      <br>
      L'inscription à la liste d'attente est le seul moyen d'être informé(e) des disponibilités. Nous contacter directement ne vous donnera pas plus de chances d'obtenir un billet.
      `,
    children: [],
    related: ['waitingListWorking', 'dontFindEventBuyer', 'knowingMyPosition'],
  },
  whatIsReserved: {
    id: 10,
    fragment: 'acheteur_billet-reserve',
    question: 'Que signifie “Réservé” sur certains billets ?',
    score: 1,
    answer: `
      Lorsqu’un billet dans la liste des billets disponibles est marqué comme <strong>Réservé</strong> 
      et non suivi du bouton Acheter, cela signifie que le billet est dans le panier 
      d’un·e autre utilisateur·rice et qu’il·elle est en train de finaliser son achat.
      <br>
      <br>
      Un billet peut rester au maximum 10 minutes dans un panier. Si le paiement n’est pas 
      effectué au bout de 10 minutes, il sera de nouveau disponible. 
      <br>
      <br>
      Certains billets peuvent être réservés avant même d’être disponibles sur la page d’achat 
      public. Il s’agit de billets en vente à un ami, ou bien de billets proposés aux 
      personnes inscrites sur liste d’attente.
      <img src="assets/img/faq/frFR/achat/9-que-signifie-reserve-sur-certains-billets.png" class="faq-screenshots" />
      `,
    children: [],
    related: ['waitingListWorking', 'knowingMyPosition'],
  },
  howManyFees: {
    id: 18,
    fragment: 'acheteur_frais-de-service',
    question: 'A combien s’élèvent les frais de service pour l’achat d’un billet ? ',
    score: 0,
    answer: `
      Nos frais de service s’ajoutent au prix de vente du billet et correspondent à un 
      pourcentage du prix de vente du billet.
      <br>
      <br>
      Ces frais peuvent sensiblement varier en fonction des <strong>protocoles de sécurité</strong> 
      mis en place pour assurer les transactions.
      <br>
      <br>
      Vous pouvez, à tout moment, accéder aux détails du prix avant la confirmation de la 
      commande en cliquant sur le bouton <strong>Acheter</strong> du billet de votre choix, puis sur 
      l’icône 
      <span class="material-icons" translate="no">info</span>
      (voir capture d’écran ci-dessous) :
      <img src="assets/img/faq/frFR/achat/17-1-a-combien-s-elevent-les-frais-de-service-pour-l-achat-d-un-billet.png" class="faq-screenshots" />
      
      <img src="assets/img/faq/frFR/achat/17-2-a-combien-s-elevent-les-frais-de-service-pour-l-achat-d-un-billet.png" class="faq-screenshots" />
      Aucuns autres frais supplémentaires ne seront prélevés sur les services de Reelax Tickets.
      <br>
      Tous les prix sont affichés T.T.C. (Toutes Taxes Comprises) sur les services de Reelax Tickets. 
      `,
    children: [],
    related: ['buyer_whyReelax'],
  },
  howToBuyToFriend: {
    id: 16,
    fragment: 'acheteur_achat-prive',
    question: 'Comment acheter un billet à un(e) ami(e) ?',
    score: 0,
    answer: `
      Lors de la mise en vente du billet, il faut que votre ami(e) coche la case <strong>Activer la revente privée pour un(e) ami(e) uniquement</strong>
      à l'étape 2 (en dessous du choix de prix). À la fin de la 
      mise en vente, il faudra ensuite qu'il/elle vous partage le lien privé du billet 
      que vous pourrez ensuite acheter.
      <img src="assets/img/faq/frFR/achat/15-comment-acheter-un-billet-a-un-e-ami-e.png" class="faq-screenshots" />
      Vous n'avez pas besoin de donner votre email au vendeur, s'il le demande c'est très probablement une arnaque. 
      <br><br>
      Soyez vigilant(e)s, des personnes malveillantes peuvent vous envoyer de fausses adresses de notre Site Web. Vérifiez systématiquement l'<span class="fw-700">adresse du site</span> (URL) et <span class="fw-700">ne partagez pas votre e-mail à des individus sur les réseaux sociaux</span>. Nos URLs commencent par https://reelax-tickets.com/ . Les sites web différents sont frauduleux (ex : "https://reelax-tickets.com.de/"). En cas de doute, contactez-nous.
      `,
    children: [],
    related: ['howToFriendSale', 'modifyTicketNameBuyer', 'multipleTickets'],
  },
  waitingListWorking: {
    id: 1,
    question: 'Comment fonctionne la liste d\'attente ?',
    fragment: 'acheteur_liste-attente',
    score: 1,
    answer: `
      Vous souhaitez acheter un billet pour l’un de nos événements, mais aucun billet 
      n’est disponible ?
      <br>
      Reelax, c’est normal les billets se vendent généralement très rapidement.
      <br>
      <br>
      Lorsqu’aucun billet n’est disponible, vous devez vous inscrire dans la 
      liste d’attente dédiée. Dès qu'un billet est mis en vente, nous envoyons 
      par e-mail un lien permettant de commander le billet à un petit groupe de personnes. 
      Ce lien est privé pendant un temps donné et permet à une personne du groupe de 
      réserver le billet.
      <br>
      Le groupe est sélectionné dans l'ordre chronologique ou aléatoire.
      <br>
      <br>
      Pour vous inscrire sur une liste d’attente, rendez-vous sur la page Reelax Tickets 
      dédiée à l’événement et cliquez sur le bouton 
      <span class="fw-700">Acheter un billet</span> puis 
      <span class="fw-700">Rejoindre la liste d'attente</span> pour vous inscrire à la liste d’attente.
      <img src="assets/img/faq/frFR/achat/2-1-comment-fonctionne-la-liste-d-attente.png" class="faq-screenshots" />
      <img src="assets/img/faq/frFR/achat/2-2-comment-fonctionne-la-liste-d-attente.png" class="faq-screenshots" />
      Ensuite, sélectionnez les catégories qui vous intéressent.
      <img src="assets/img/faq/frFR/achat/2-3-comment-fonctionne-la-liste-d-attente.png" class="faq-screenshots" />
      À l’étape 2, vous devrez vous connecter (ou vous inscrire en confirmant votre email si vous n’avez pas de compte) et renseignez vos informations personnelles.
      <br>
      <br>
      À l’étape 3, validez et choisissez votre prix maximum ainsi que la quantité de billets désirés.
      <img src="assets/img/faq/frFR/achat/2-4-comment-fonctionne-la-liste-d-attente.png" class="faq-screenshots" />
      Enfin en validant, vous serez inscrit·e dans la liste d’attente, 
      vous recevrez des mails dès lors que ce sera votre tour et qu’un billet 
      correspondra à vos attentes.
      <br>
      <br>
      Pour la plupart des événements, il est également possible d’activer l’achat automatique. 
      Cette fonctionnalité vous permet d’acheter automatiquement votre billet plutôt que d’être 
      alerté·e par e-mail. Cette option facultative ne vous garantit pas d’obtenir un billet, 
      mais vous évitera de surveiller constamment vos e-mails.
      <br>
      Si vous n’avez pas activé l’achat automatique, une fois que vous recevrez l’e-mail 
      indiquant qu'un billet est disponible, il vous faudra être rapide pour être sûr·e
      de vous le procurer (vous serez généralement entre 10 à 50 à recevoir l’e-mail). 
      <br>
      <br>
      Pour cela, il vous suffira de cliquer sur le bouton <strong>Accéder au billet</strong> 
      dans l'e-mail !
      <img src="assets/img/faq/frFR/achat/2-5-comment-fonctionne-la-liste-d-attente.png" class="faq-screenshots" />
      <strong>Ne vous en faites pas, si vous avez raté un billet, 
      vous aurez de grandes chances de recevoir les prochaines alertes, 
      et ce, jusqu’à ce que vous ayez pu acheter le nombre de billets désiré !</strong>
      <br>
      <br>
      Enfin, si vous souhaitez modifier votre inscription (catégorie, nombre de billets 
      recherchés, achat automatique…), vous pouvez modifier votre inscription depuis 
      la page
      <a href="/mon-compte/mes-listes-d-attente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mes listes d'attente</a>. 
      Cliquez alors sur le bouton <strong>Modifier mon inscription</strong> afin d’y apporter des modifications.
      `,
    children: [],
    related: ['knowingMyPosition', 'activateDesactivateAutoPurchase', 'multipleTickets', 'activateDesactivateAutoPurchase'],
  },
  modifyWaitingList: {
    id: 4,
    question: 'Est-ce que je peux modifier mon inscription dans la liste d’attente ?',
    fragment: 'acheteur_modification-inscription',
    score: 1,
    answer: `
      Oui ! Vous pouvez modifier votre inscription (changer le nombre de billets recherchés, 
      activer ou désactiver l’achat automatique, se désinscrire…) dans une liste d’attente à 
      tout moment depuis la page 
      <a href="/mon-compte" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mon Compte</a>. 
      <br>
      <strong>Votre place initiale reste la même</strong>, même si vous apportez des modifications 
      (ajout de nouveaux tarifs, modification du prix, du nombre de billets désiré, 
      activation de l'achat automatique…) à votre inscription. 
      `,
    children: [],
    related: ['waitingListWorking', 'activateDesactivateAutoPurchase', 'knowingMyPosition'],
  },
  losingPlaceWaitingList: {
    id: 5,
    fragment: 'acheteur_modification-rang',
    question: 'Si je modifie mon inscription, est-ce que je perds ma place dans la liste d’attente ? ',
    score: 1,
    answer: ` Non ! <strong>Votre place initiale reste la même</strong>, même si 
      vous apportez des modifications (ajout de nouveaux tarifs, modification du prix, 
      du nombre de billets désiré, activation de l'achat automatique…) à votre 
      inscription.
      <br>De la même manière, si vous vous désinscrivez et réactivez votre 
      inscription plus tard, vous retrouverez votre place initiale.
      `,
    children: [],
    related: ['modifyWaitingList', 'multipleTickets', 'waitingListWorking'],
  },
  knowingMyPosition: {
    id: 2,
    question: 'Comment connaître mon classement dans la liste d’attente ?',
    fragment: 'acheteur_rang',
    score: 1,
    answer: `
      Vous pouvez désormais avoir plus de visibilité sur votre rang dans la page
      <a href="/mon-compte/mes-listes-d-attente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mes listes d'attente</a>.
      <br>
      <img src="assets/img/faq/frFR/achat/3-classement-liste-d-attente.png" class="faq-screenshots" />
      Le curseur correspond à votre position et vos chances d’obtenir des billets seront optimales lorsque la jauge colorée dépassera votre prénom.
      <br>
      <br>
      Pour information, voici le fonctionnement de la liste d'attente : dès qu'un billet 
      est mis en vente, notre système envoie une alerte par e-mail comportant un lien privé 
      pour acheter le billet à un petit groupe de personnes qui sera sélectionné dans l'ordre 
      chronologique (90% pour la plupart des événements) ou aléatoire (10%).
      `,
    children: [],
    related: ['waitingListWorking', 'stepbackInJauge', 'modifyWaitingList', 'multipleTickets', 'activateDesactivateAutoPurchase'],
  },
  stepbackInJauge: {
    id: 3,
    question: 'Pourquoi j’ai l’impression de reculer sur la jauge ?',
    fragment: 'acheteur_jauge_reculer',
    score: 1,
    answer: `
      Si vous avez l’impression que vous avancez moins vite ou que votre pourcentage diminue, c’est normal car les inscriptions sur la liste d'attente augmentent à l’approche de l’événement. 
      <br>
      <br>
      Mais ne vous inquiétez pas, cela n'a pas d'incidence sur votre classement dans la liste d'attente, votre classement reste le même.
      <br>
      <br>
      Pour information, voici le fonctionnement de la liste d'attente : dès qu'un billet est mis en vente, notre système envoie une alerte par e-mail comportant un lien privé pour acheter le billet à un petit groupe de personnes qui sera sélectionné dans l'ordre chronologique (90% pour la plupart des événements) ou aléatoire (10%).
      `,
    children: [],
    related: ['waitingListWorking', 'modifyWaitingList'],
  },
  multipleTickets: {
    id: 6,
    fragment: 'acheteur_chances-billets',
    question: 'Je cherche plusieurs billets. Est-ce que ça réduit mes chances d’avoir des billets ? ',
    score: 1,
    answer: `<strong>Non</strong>, le nombre de billets recherché n'a pas d'influence sur l'attribution 
    des alertes. Toutes les alertes contiennent uniquement un billet. Si vous recherchez 
    plusieurs billets, vous recevrez des alertes jusqu’à ce que vous ayez obtenu le nombre 
    de billets désiré.
    `,
    children: [],
    related: ['waitingListWorking', 'losingPlaceWaitingList', 'knowingMyPosition', 'activateDesactivateAutoPurchase', 'purchaseOptionFunctioning'],
  },
  quantityLimit: {
    id: 84,
    fragment: 'acheteur_quantite-limitee',
    question: 'Pourquoi je ne peux pas obtenir autant de billets que je veux via la liste d’attente ?',
    score: 1,
    answer: `
    Chaque inscription à la liste d’attente permet d’obtenir un nombre limité de billets parmi toutes les catégories. Cette limite peut varier en fonction des événements.
    <br>
    <br>
    Le nombre maximal de billets auxquels vous pouvez prétendre est indiqué lors de l'inscription en liste d'attente.
    <br>
    <br>
    Cette limite permet de laisser une chance à tous d’obtenir des billets. 
    `,
    children: [],
    related: ['modifyWaitingList', 'multipleTickets'],
  },
  purchaseOptionFunctioning: {
    id: 67,
    fragment: 'acheteur_options-achat-auto',
    question: 'Comment fonctionnent les options d\'achat lorsqu’on active l’achat automatique ?',
    score: 0,
    answer: `À l’étape 3 au moment de votre inscription en liste d’attente, lorsque vous activez l’achat automatique, deux options facultatives vous seront proposées si vous cherchez plusieurs billets :
    <br><br>
    - Je souhaite aller à l’événement seulement si j’obtiens 2 billets.
    <br>
    - Je souhaite obtenir uniquement des billets de la même catégorie.
    <br><br>
    <img src="assets/img/faq/frFR/achat/8-1Comment-fonctionnent-les-choix-d’options-lorsqu’on-active-l’achat-automatique.png" class="faq-screenshots" />
    Pour information, ces options peuvent diminuer vos chances d'être sélectionné.e, mais elles peuvent vous permettre d'affiner votre recherche. :)<br>
    Vous pouvez activer les deux options simultanément ou alors individuellement et les désactiver depuis votre espace Mon compte
    `,
    children: [],
    related: ['waitingListWorking', 'losingPlaceWaitingList', 'knowingMyPosition', 'activateDesactivateAutoPurchase', 'multipleTickets'],
  },
  dontReceiveAlerts: {
    id: 13,
    fragment: 'acheteur_alertes-mails',
    question: 'Je ne reçois plus d’alertes e-mails. Est-ce que mon tour est passé ? ',
    score: 0,
    answer: `Non, cela ne veut pas dire que votre tour est passé ! 
      <br>
      Nous continuons à vous envoyer des alertes tant que vous êtes inscrits·es et que vous 
      n'obtenez pas tous les billets demandés.
      `,
    children: [],
    related: ['waitingListWorking', 'knowingMyPosition'],
  },
  desinscriptionWaitingList: {
    id: 64,
    fragment: 'acheteur_desinscription',
    question: 'Comment me désinscrire de la liste d’attente ? ',
    score: 0,
    answer: `Si vous souhaitez vous désinscrire d’une liste d’attente, vous pouvez vous rendre sur la page
      <a href="/mon-compte/mes-listes-d-attente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mes listes d'attente</a>
      et cliquer sur le bouton <strong>Se désinscrire de toutes les catégories</strong>.
      Si vous souhaitez vous désinscrire uniquement de certaines catégories, il faudra cliquer sur le bouton <strong>Modifier mon inscription</strong> afin d’appliquer les changements en détail.
      `,
    children: [],
    related: ['canICancelSale', 'activateDesactivateAutoPurchase'],
  },
  availableTicketsNoAlert: {
    id: 71,
    fragment: 'acheteur_pas-alertes',
    question: 'Pourquoi des billets sont disponibles sur la page de l’événement, mais je n’ai pas reçu d’alertes alors que je suis inscrit.e en liste d’attente ?',
    score: 0,
    answer: `Dès qu'un billet est mis en vente, nous envoyons par e-mail un lien permettant de commander le billet à un petit groupe de personnes dans l'ordre chronologique (90 % de chance) ou aléatoire (10 % de chance). Si le billet n’est pas commandé sous 24 heures, le billet devient alors disponible sur la page publique et peut être commandé par n’importe qui.
    <br>
    <br>
    Si vous n'avez pas reçu d'alertes vous indiquant qu'un billet était disponible, c'est que votre tour n'est pas encore arrivé, mais vous restez toujours prioritaires pour les alertes de billets disponibles en étant inscrits.es dans la liste d’attente. 
    Vous pouvez en savoir plus sur votre classement ici : <a href="/mon-compte/mes-listes-d-attente" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mes listes d'attente</a>    
      `,
    children: [],
  },
  whyActivateAutomaticPurchase: {
    id: 77,
    fragment: 'acheteur_pourquoi-achat-automatique',
    question: 'Pourquoi activer l’achat automatique ?',
    score: 1,
    answer: `
    La fonctionnalité d’achat automatique est <span class="fw-700">gratuite</span> et vous permet d’<span class="fw-700">éviter de surveiller vos notifications</span> nuit et jour, à la quête d’un billet. Lorsque votre tour arrive, le billet est acheté directement. 
    <br><br>
    Pour l’activer, vous devez simplement réaliser <span class="fw-700">une authentification 3D Secure</span>, nous permettant de vous débiter <span class="fw-700">le montant que vous avez autorisé</span>, lorsque votre tour arrive. 
    <br><br>
    Lorsque vous activez cette fonctionnalité, vous pouvez gérer les paramètres fixés, à tout moment, depuis votre <span class="fw-700">Compte</span>, dont : <br>
    - La <span class="fw-700">date limite de débit</span> ; <br>
    - La <span class="fw-700">suppression de la carte bancaire</span> renseignée ;<br>
    - La <span class="fw-700">modification de la carte bancaire</span> ; <br>
    - Et la <span class="fw-700">désactivation</span> de la fonctionnalité, quand vous le souhaitez. <br>
    <br>
    Les informations bancaires ne sont pas stockées sur Reelax Tickets : elles sont directement transmises à MangoPay et sont traitées en toute <span class="fw-700">sécurité</span>.
    <br><br>
    Le montant maximum que vous autorisez doit être <span class="fw-700">disponible</span> sur votre carte bancaire. Aucun montant n’est gelé sur votre compte bancaire. Si la transaction ne peut aboutir, l’achat automatique sera désactivé. 
    <br><br>
    Pour rappel, votre place initiale reste la même, même si vous apportez des modifications (ajout de nouveaux tarifs, modification du prix, du nombre de billets désiré, activation de l'achat automatique…) à votre inscription. 
    `,
    children: [],
    related: ['activateDesactivateAutoPurchase', 'modifyCreditCard', 'waitingListWorking'],
  },
  activateDesactivateAutoPurchase: {
    id: 7,
    fragment: 'acheteur_achat-automatique',
    question: 'Comment activer / désactiver l’achat automatique ?',
    score: 1,
    answer: `Sur la plupart des événements, plutôt que d’être alerté(e) par e-mail, 
      il est possible d’<strong>acheter automatiquement</strong> votre billet ! Cette fonctionnalité 
      facultative ne vous garantit pas d'avoir des billets, mais vous évitera de 
      surveiller jour et nuit vos notifications. 
      <br>
      <br>
      Après s’être inscrit·e sur la liste d’attente de votre choix, cliquez sur 
      <strong>Modifier mon inscription</strong> puis allez à l’<strong>étape 3</strong>.
      <img src="assets/img/faq/frFR/achat/7-1-comment-activer-desactiver-l-achat-automatique.png" class="faq-screenshots" />
      Vous pouvez alors activer l’achat automatique et <strong>définir la date 
      limite au-delà de laquelle vous ne souhaitez plus être débité·e</strong>. 
      <br>
      <br>
      Ensuite, renseignez les informations du détenteur·rice du billet puis vos 
      informations bancaires.
      <img src="assets/img/faq/frFR/achat/7-2-comment-activer-desactiver-l-achat-automatique.png" class="faq-screenshots" />
      ⚠️ <strong>Attention, votre moyen de paiement doit être une carte bancaire Visa ou 
      Mastercard et doit être éligible à l’authentification 3D Secure. </strong>
      Puis, cliquez sur le bouton <strong>Confirmer</strong> pour valider l’activation 
      de l’achat automatique. 
      <br>
      Une fois que l’achat automatique est activé, <strong>vous recevrez un e-mail avec un 
      récapitulatif.</strong>
      <img src="assets/img/faq/frFR/achat/7-3-comment-activer-desactiver-l-achat-automatique.png?v=2" class="faq-screenshots" />
      Si vous devez changer votre mode de paiement pour l’achat automatique, 
      vous pouvez le faire directement en allant sur la page
      <a href="/mon-compte/mes-listes-d-attente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mes listes d'attente</a>, 
      en cliquant sur le bouton <strong>“Modifier mon inscription”</strong>. 
      <br>
      Vous pouvez <strong>désactiver</strong> l’achat automatique à tout moment. Il vous 
      suffit d’aller sur votre 
      <a href="/mon-compte" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Compte</a>
      , puis depuis l’événement concerné, vous pouvez cocher/décocher l'option d'achat automatique. 
      <br>
      Pour rappel, votre place initiale reste la même, même si vous apportez des modifications (ajout de nouveaux tarifs, modification du prix, du nombre de billets désiré, activation de l'achat automatique…) à votre inscription. 
      `,
    children: [],
    related: ['modifyCreditCard', 'waitingListWorking', 'knowingMyPosition', 'modifyWaitingList', 'multipleTickets'],
  },
  cantActivateAutoPurchase: {
    id: 14,
    fragment: 'acheteur_refus-achat-automatique',
    question: 'Je n’arrive pas à activer l’achat automatique. Que faire ?',
    score: 0,
    answer: `
      Pour pouvoir activer l’achat automatique, votre moyen de paiement doit être une carte 
      bancaire Visa ou Mastercard et doit être éligible à l’authentification 3D Secure. 
      Les cartes Amex et certaines cartes étrangères ne permettent pas d’activer cette 
      fonctionnalité. 
      <br>
      <br>
      Veillez à bien renseigner tous les champs (Prix maximum, Nombre de billets recherchés, 
      Date d’expiration de l’inscription, Noms des détenteurs-rices des billets, Carte bancaire…). 
      <br>
      <br>
      ll est possible que vous ne puissiez pas activer l'achat automatique à l'approche de l'événement. C'est normal : pour éviter les mauvaises surprises, l'achat automatique est désactivé la veille de l'événement.
      `,
    children: [],
    related: ['activateDesactivateAutoPurchase', 'knowingMyPosition'],
  },
  modifyCreditCard: {
    id: 9,
    fragment: 'acheteur_modifier-achat-automatique',
    question: 'Comment modifier la carte bancaire renseignée pour l’achat automatique ? ',
    score: 0,
    answer: `
      Si vous devez changer votre mode de paiement pour l’achat automatique, 
      vous pouvez le faire directement en allant sur la page
      <a href="/mon-compte/mes-listes-d-attente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mes listes d'attente</a>
      , et en cliquant sur le bouton <strong>Modifier mon inscription</strong>.
      <img src="assets/img/faq/frFR/achat/8-1-comment-modifier-la-carte-bancaire-renseignee-pour-l-achat-automatique.png" class="faq-screenshots" />
      Vous pouvez aller directement à l’étape 3, puis 
      cliquez sur <strong>Changer de carte bancaire</strong>. 
      ⚠️ <strong>Attention, votre moyen de paiement doit être une carte bancaire Visa 
      ou Mastercard et doit être éligible à l’authentification 3D Secure. </strong>
      <img src="assets/img/faq/frFR/achat/8-2-comment-modifier-la-carte-bancaire-renseignee-pour-l-achat-automatique.png" class="faq-screenshots" />
      Après avoir renseigné les nouvelles informations bancaires, 
      vous pouvez cliquer sur <strong>Confirmer</strong>. 
      `,
    children: [],
    related: ['activateDesactivateAutoPurchase'],
  },
  autoPurchaseEventDate: {
    id: 8,
    fragment: 'acheteur_achat-automatique-jour-event',
    question: 'Pourquoi je ne peux plus activer l’achat automatique le jour de l’événement ?',
    score: 1,
    answer: `
      Le jour de l’événement, nous désactivons l’achat automatique pour tout le monde pour éviter les mauvaises surprises.
      <br>
      Pour les festivals, l’achat automatique est désactivé à partir du premier jour de l’événement, cependant vous recevrez les alertes e-mails lorsque des billets sont disponibles. :)
      <br><br>
      Cela ne modifie pas votre classement dans la liste d’attente, pensez à vérifier vos boites mails/spams régulièrement.
      `,
    children: [],
    related: ['waitingListWorking', 'knowingMyPosition', 'modifyWaitingList', 'multipleTickets', 'activateDesactivateAutoPurchase'],
  },
  dontReceiveAlertSinceAutoPurchase: {
    id: 15,
    fragment: 'acheteur_alertes-achat-automatique',
    question: 'Pourquoi je ne reçois plus d’e-mail d’alerte depuis l’activation de l’achat automatique ?',
    score: 0,
    answer: `
      Aucun e-mail d’alerte ne vous sera envoyé·e si vous activez l’achat automatique. 
      L'achat automatique vous évite de surveiller vos e-mails.
      <br>
      Lorsque c’est votre tour, le billet sera acheté directement ! 
      <br>
      <br>
      Cependant, le jour de l’événement, nous désactivons l’achat automatique pour tout 
      le monde pour éviter les mauvaises surprises. Dans ce cas, si c'est votre tour, 
      vous serez alerté·e par e-mail.
      `,
    children: [],
    related: ['waitingListWorking', 'activateDesactivateAutoPurchase', 'losingPlaceWaitingList'],
  },
  SMSAlertAutomaticPurchase: {
    id: 75,
    fragment: 'acheteur_sms-achat-automatique',
    question: 'Comment être alerté·e par SMS, en cas d’achat automatique ? ',
    score: 0,
    answer: `Désormais, en plus d’être alerté·e par mail, vous pouvez également être alerté·e par SMS, en cas d’achat automatique d’un billet ! 
    <br><br>
    Vous pouvez renseigner votre numéro de téléphone sur le champ suivant, lors de l’activation de l’achat automatique : 
    <img src="assets/img/faq/frFR/achat/75-1-sms-achat-auto.png" class="faq-screenshots" />
    <img src="assets/img/faq/frFR/achat/75-2-sms-achat-auto.jpg" class="faq-screenshots" />
    A noter : certains pays dans lesquels nous avons très peu d'utilisateurs ne sont pas éligibles à la réception de nos SMS. 
    <br>
    Vous pouvez retrouver vos billets et vos inscriptions depuis votre espace <a href="/mon-compte/mes-billets" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mon compte</a>.
    `,
    children: [],
    related: ['dontReceiveAlertSinceAutoPurchase', 'waitingListWorking', 'knowingMyPosition'],
  },
  deleteCardAutomaticPurchase: {
    id: 76,
    fragment: 'acheteur_supprimer-carte-bancaire',
    question: 'Comment supprimer la carte bancaire renseignée pour l’achat automatique ?',
    score: 0,
    answer: `Reelax Tickets n’enregistre aucune coordonnée bancaire dans son système. Nous les transférerons directement à notre prestataire de paiement sécurisé, Mangopay. 
    <br><br>
    Vous pouvez supprimer la carte bancaire renseignée pour l’achat automatique directement depuis
    <a href="/mon-compte/mes-listes-d-attente" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mes listes d'attente</a>.
    <img src="assets/img/faq/frFR/achat/76-comment-supprimer-carte-bancaire.png" class="faq-screenshots" />
    `,
    children: [],
    related: ['activateDesactivateAutoPurchase', 'modifyCreditCard', 'dontReceiveAlertSinceAutoPurchase'],
  },
  pickupTicket: {
    id: 11,
    fragment: 'acheteur_recuperer-billets',
    question: 'Comment est-ce que je récupère les billets après mon achat ? ',
    score: 0,
    answer: `
      Vous recevrez systématiquement un e-mail à l’adresse associée à votre compte Reelax, 
      sur lequel le bouton Télécharger mon billet vous permet d’obtenir directement le billet 
      au format PDF.
      <br>
      <br>
      <strong>Attention, si vous ne trouvez pas l’e-mail, pensez bien à vérifier votre onglet 
      Courriers indésirables ou Promotions dans votre boîte mail ! </strong>
      <br>
      <br>
      Si vous avez un compte Reelax Tickets, vous pouvez également récupérer votre billet en 
      format PDF depuis votre Compte, dans l’onglet 
      <a href="/mon-compte/mes-billets" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mes Billets</a>
      .
      <img src="assets/img/faq/frFR/achat/10-1-comment-est-ce-que-je-recupere-les-billets-apres-mon-achat.png?v=2" class="faq-screenshots" />
      Si vous n'avez pas de compte, vous pouvez récupérer votre billet après avoir créé un 
      compte <strong>avec la même adresse e-mail</strong>. Une fois l'adresse e-mail validée, 
      vous retrouverez votre billet sur la page 
      <a href="/mon-compte/mes-billets" target="_blank"class="link --primary"><span class="material-icons" translate="no">launch</span>Mes Billets</a>
      . 
      <br>
      <br>
      Il se peut que le message <strong>“Billet bientôt disponible”</strong> apparaisse sur votre espace 
      personnel. Cela veut dire que votre nouveau billet est en cours de création, pour 
      vous garantir l’accès à l’événement. Ce délai peut être de 24h maximum à 15 minutes, 
      le jour de l’événement.
      <img src="assets/img/faq/frFR/achat/10-2-comment-est-ce-que-je-recupere-les-billets-apres-mon-achat.png" class="faq-screenshots" />
      `,
    children: [],
    related: ['modifyTicketNameBuyer'],
  },
  invoiceAfterBuying: {
    id: 65,
    fragment: 'acheteur_facture',
    question: 'Puis-je me procurer une facture pour mon achat ?',
    score: 0,
    answer: `
      Oui, si vous disposez d’un compte Reelax Tickets, vous pouvez retrouver la facture de nos frais de service sur la page <a href="/mon-compte/mes-billets#Achat" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Mes Billets</a>.
      <img src="assets/img/faq/frFR/achat/23-puis-je-me-procurer-une-facture-pour-mon-achat.png?v=2" class="faq-screenshots" />
      Sinon, il faut vous créer un compte en utilisant l’adresse e-mail avec laquelle vous avez acheté votre billet.
      <br>
      Pour information, <strong>nous facturons exclusivement des frais de services</strong> sur le montant de la vente du billet pour l’utilisation de notre plateforme en ligne. La TVA (20 %) est facturée au moment du paiement et porte sur le total des frais de service. L’acheteur-se paie le montant de la commande au vendeur ou à la vendeuse par l’intermédiaire de Reelax Tickets. Vous trouverez un récapitulatif du total de la transaction dans l’e-mail “Merci pour votre Achat”
      `,
    children: [],
  },
  emailMistakeInPurchase: {
    id: 61,
    fragment: 'acheteur_erreur-email',
    question: 'Je me suis trompé(e) d\'adresse email : comment récupérer mon billet ?',
    score: 0,
    answer: `
      Vous pouvez nous contacter à l'adresse support@reelax-tickets.com en nous donnant le plus d'informations possibles (nom et prénom du détenteur ou de la détentrice du billet, événement, catégorie, date et heure de l'achat, adresse e-mail avec l'erreur de saisie).
      <br>
      Nous serons ensuite en mesure de vous renvoyer votre billet par e-mail. 
      `,
    children: [],
    related: ['howToSell', 'cantFindConfirmationMail', 'cantValidIKYC'],
  },
  dontReceiveTicket: {
    id: 17,
    fragment: 'acheteur_probleme-reception-billet',
    question: 'Je ne reçois pas mon billet alors que j’ai payé. Que faire ?',
    score: 0,
    answer: `
    Cela peut être dû à un paiement échoué, si vous n’avez pas attendu la fin de l’étape de 
    paiement 3D Secure. Dans ce cas, un e-mail vous sera envoyé·e sous 30 minutes pour vous 
    signifier que le paiement n’a pas abouti. Il vous suffira alors de cliquer sur le lien 
    dans l’e-mail pour retenter le paiement.
    <img src="assets/img/faq/frFR/achat/16-je-ne-recois-pas-mon-billet-alors-que-j-ai-paye-que-faire.png?v=2" class="faq-screenshots" />
    Si vous avez passé cette étape mais que vous n’avez toujours pas reçu votre billet, 
    l’e-mail contenant votre billet s’est peut-être glissé dans vos spams ou Promotions. 
    `,
    children: [],
    related: ['emailMistakeInPurchase', 'pickupTicket'],
  },
  canICancelSale: {
    id: 60,
    fragment: 'acheteur_annuler-achat',
    question: 'Je souhaite annuler mon achat. Puis-je me faire rembourser ma place ?',
    score: 0,
    answer: `
      En dehors de l’annulation ou le report de l’événement, conformément à l'article L.121-28 du Code de la Consommation, les achats de Billets sur Reelax Tickets ne peuvent faire l'objet d'un droit de rétractation. Le billet n’est ni échangeable, ni remboursable.
      <br>
      <br>
      Néanmoins, si votre billet ne vous intéresse plus, vous pouvez le revendre facilement depuis votre 
      <a href="/mon-compte" target="_blank" class="link --primary"><span class="material-icons" translate="no">launch</span>Compte</a>
      .
      <br>
      Attention, pour des raisons de sécurité, nous pouvons limiter le nombre de reventes par billet. Si la mise en vente n'aboutit pas, cliquez sur le bouton <strong>Demande d'aide</strong>. Nous recevrons alors votre billet et pourrons vous guider !
      `,
    children: [],
    related: ['howToSell', 'ticketNotResale'],
  },
  buyer_whyReelax: {
    id: 62,
    fragment: 'acheteur_pourquoi-reelax',
    question: 'Pourquoi Reelax Tickets est la plateforme de revente la plus sécurisée pour acheter et vendre vos billets d\'occasions ?',
    score: 0,
    answer: 'Nous utilisons le plus haut protocole de sécurité pour vous protéger de la fraude aux billets. Concrètement, nous sommes connectés aux bases de données des billetteries pour vérifier si les billets mis en vente sur notre plateforme sont valides. Ensuite, dès qu’un billet est acheté sur notre plateforme, nous créons instantanément un <strong>nouveau billet à votre nom</strong>.',
    children: [],
    related: ['howManyFees'],
  },
  differentPage: {
    id: 63,
    fragment: 'acheteur_site-different',
    question: 'Pourquoi suis-je sur une page différente du site de l’organisateur ?',
    score: 0,
    answer: 'Vous vous trouvez sur la page dédiée à l’événement sur notre plateforme. L’organisateur a fait appel à la bourse d’échange Reelax Tickets pour <strong>sécuriser la revente</strong> de ses billets.',
    children: [],
    related: ['buyer_whyReelax'],
  },
  softwareCompatibility: {
    id: 41,
    fragment: 'organisateur_logiciel-compatible',
    question: 'Mon logiciel est-il compatible avec Reelax Tickets ?',
    score: 1,
    answer: 'Tous les logiciels français sont <span class="fw-700">compatibles</span> avec notre service de bourses aux billets sécurisées.<br>Avec nos solutions partenaires les intégrations sont plus poussées et il suffit d’activer la revente en un clic directement depuis votre logiciel.',
    children: [],
  },
  priceSettingUp: {
    id: 42,
    fragment: 'organisateur_cout',
    question: 'Combien coûte la mise en place d’une bourse aux billets pour mon événement ?',
    score: 1,
    answer: '<span class="fw-700">0 euro !</span> Reelax Tickets prélève uniquement des frais de service aux acheteurs de billets.',
    children: [],
  },
  howSettingUp: {
    id: 48,
    fragment: 'organisateur_mise-en-place',
    question: 'Comment se déroule de A à Z la mise en place de votre bourse aux billets ?',
    score: 0,
    answer: `1 - Renseigner quelques questions pour le <span class="fw-700">paramétrage de votre bourse aux billets</span> (date de mise en ligne, plan de salle, image, couleur de votre plateforme, ...)
      <br><br>2 - Activer la <span class="fw-700">connexion sur votre billetterie</span> (selon votre billetterie et vos distributeurs, nous pouvons vous demander un accès restreint à votre logiciel de billetterie).
      <br><br>3 - Signer notre <span class="fw-700">convention de partenariat</span> et le tour est joué !
      <br><br>Dès lors, nous vous envoyons vos identifiants et votre bourse aux billets. Il ne vous reste plus qu’à la <span class="fw-700">partager auprès de vos spectateurs</span> (et soyez sûr qu’ils vont adorer) !
      `,
    children: [],
  },
  timeSettingUp: {
    id: 43,
    fragment: 'organisateur_temps-installation',
    question: 'Combien de temps est nécessaire pour mettre en place la solution ?',
    score: 0,
    answer: 'Les bourses aux billets sont généralement créées en <span class="fw-700">quelques heures</span>.<br>Après un court appel avec l\'un de nos account managers, nous paramétrons la bourse aux billets <span class="fw-700">selon vos souhaits</span> en deux temps trois mouvements.',
    children: [],
  },
  reelaxConsultant: {
    id: 56,
    fragment: 'organisateur_accompagnement',
    question: 'Est-ce que je suis accompagné par un conseiller chez Reelax Tickets ?',
    score: 1,
    answer: `<span class="fw-700">Oui !</span>
      <br><br>De la création à la fin de votre événement et même bien après, nos conseillers vous répondront en moyenne en moins d'une heure en semaine et en moins de deux heures le week-end !
      <br><br>L'objectif N°1 de Reelax Tickets est la <span class="fw-700">satisfaction de nos organisateurs partenaires et de leur public</span> ! Il n’y a pas de plus grande fierté pour notre équipe qu’un partenaire qui recommande sincèrement notre service à un autre organisateur.
      `,
    children: [],
  },
  canIRefundBuyers: {
    id: 51,
    fragment: 'organisateur_annulation-report',
    question: 'Mon événement est reporté ou annulé, puis-je rembourser les derniers détenteurs-rices des billets avec Reelax Tickets ?',
    score: 0,
    answer: `<span class="fw-700">Bien sûr !</span>
      <br><br>Pour les billets issus de votre <span class="fw-700">logiciel de billetterie</span>, vous avez la possibilité de rembourser les acheteurs qui le souhaitent en toute simplicité (soit via un fichier XML à envoyer à votre banque, soit via notre intermédiaire).
      <br><br>Concernant les billets provenant des <span class="fw-700">distributeurs</span>, nous remboursons automatiquement les acheteurs puisque le paiement des vendeurs se fait après l’événement pour ces billets.
      `,
    children: [],
  },
  presenceReelax: {
    id: 47,
    fragment: 'organisateur_sur-place',
    question: 'Est-ce que Reelax Tickets est présent le jour de l\'événement ?',
    score: 1,
    answer: 'Si votre bourse aux billets est victime de son succès et que vous <span class="fw-700">ressentez le besoin</span> que l’on vienne vous épauler le jour J, un account manager se fera un plaisir de venir vous prêter main forte au contrôle d’accès.',
    children: [],
  },
  whoTakesCareSAV: {
    id: 50,
    fragment: 'organisateur_responsabilite-sav',
    question: 'Qui se charge du SAV ?',
    score: 1,
    answer: `C’est encore <span class="fw-700">nous</span> (mais seulement si vous êtes d’accord) !
      <br><br>Avec l’ambition d’améliorer constamment notre service, nous avons à cœur de travailler au plus près de nos utilisateurs pour identifier et comprendre leurs besoins.
      `,
    children: [],
  },
  organisateur_whyReelax: {
    id: 45,
    fragment: 'organisateur_pourquoi-reelax',
    question: 'Pourquoi Reelax Tickets est-elle la plateforme la plus sécurisée du marché ?',
    score: 0,
    answer: 'Reelax Tickets permet uniquement la revente des billets de ses <span class="fw-700">événements partenaires</span>. Ce choix nous permet de garantir la sécurité de tous les billets échangés sur notre service.',
    children: [],
  },
  howSecure: {
    id: 46,
    fragment: 'organisateur_securite',
    question: 'Comment sécurisons-nous les échanges de billets ?',
    score: 1,
    answer: `Notre protocole informatique se connecte automatiquement à votre billetterie et vos éventuels distributeurs afin de <span class="fw-700">vérifier la validité</span> du code d'accès du billet mis en vente.
      <br><br>Ensuite dès qu'un billet est vendu sur votre bourse aux billets, nous le reconditionnons, c’est-à-dire que <span class="fw-700">nous invalidons le code d'accès du vendeur</span> et attribuons <span class="fw-700">nouveau code d'accès à l'acheteur</span>.
      <br><br>Dans certains cas, lorsque votre billetterie ne nous permet pas de modifier les codes d'accès, le billet n'est pas reconditionné, mais l’argent du vendeur ou de la vendeuse est bloqué jusqu’à la fin de l’événement.
      `,
    children: [],
  },
  whyDoingThat: {
    id: 57,
    fragment: 'organisateur_demarche',
    question: 'Pourquoi faites-vous tout ça ?',
    score: 0,
    answer: `L'équipe fondatrice de Reelax Tickets se compose de 4 anciens diplômés des Mines-Télécom qui partagent tous le besoin d’avoir un <span class="fw-700">impact positif</span> sur la société ainsi qu’une <span class="fw-700">passion viscérale pour la musique</span>, et ce, à travers différents prismes (organisateurs d'événements, musiciens et spectateurs).
      <br><br>Après avoir constaté auprès d’une centaine d’organisateurs que le fonctionnement du marché secondaire de la billetterie était une complète aberration. L'équipe a développé pendant plus de deux ans, un protocole informatique <span class="fw-700">unique en Europe</span> afin de <span class="fw-700">faciliter les transactions</span> de billets entre particuliers et de <span class="fw-700">supprimer la fraude</span>. Ce protocole est aujourd'hui <span class="fw-700">interopérable</span> avec plus de 20 logiciels de billetterie et utilisé par de nombreux organisateurs.
      <br>Devant la très forte adoption des organisateurs français, nous sommes convaincus que la France peut devenir un exemple pour de nombreux pays européens !
      <br><br>Si l’aventure pour assainir le marché secondaire de la billetterie en France et en Europe vous inspire ou si vous souhaitez simplement nous donner un coup de pouce, n’hésitez pas à <span class="fw-700">nous écrire</span> à : <a class="link --primary" href="mailto:organisateurs@reelax-tickets.com">organisateurs@reelax-tickets.com</a>
      `,
    children: [],
  },
  whereAreWe: {
    id: 58,
    fragment: 'organisateur_se-rencontrer',
    question: 'Où nous trouver ?',
    score: 0,
    answer: `Reelax Tickets est une société française basée à Rennes
      <br><br>Notre équipe navigue entre notre bureau de l’IMT Atlantique (<span class="fw-700">Rennes</span>), notre deuxième bureau à Telecom Paritech (<span class="fw-700">Paris 14</span>) et dans les <span class="fw-700">plus grands événements de France</span>.
      <br><br>Mais quand bien même nos sillons sont nombreux, vous trouverez toujours quelqu’un pour vous accueillir à bras ouverts <span class="fw-700">ici :</span> <a class="link --primary" href="mailto:organisateurs@reelax-tickets.com">organisateurs@reelax-tickets.com</a>
      `,
    children: [],
  },
  interferenceDashboard: {
    id: 55,
    fragment: 'organisateur_journal-operation-bordereau-recette',
    question: 'Est-ce que Reelax Tickets interfère dans mon journal des opérations ou mon bordereau de recette ?',
    score: 1,
    answer: 'Reelax Tickets agit en opérateur du système de billetterie de l’organisateur en y enregistrant une mise à jour du code d’accès, mais <span class="fw-700">n’agit pas sur l’enregistrement fiscal obligatoire de l’opération de vente initiale ni sur son montant</span>.',
    children: [],
  },
  reelaxInTicketingSystem: {
    id: 53,
    fragment: 'organisateur_systemes-billetteries',
    question: 'Est-ce que je dois déclarer Reelax Tickets dans la déclaration de mes systèmes de billetterie informatisée ?',
    score: 0,
    answer: '<span class="fw-700">Non</span>, Reelax Tickets n\'est pas un système de billetterie tel que défini par la législation. Vous n\'avez donc <span class="fw-700">aucune déclaration à réaliser</span>.',
    children: [],
  },
  integrabilityData: {
    id: 44,
    fragment: 'organisateur_logiciel-data',
    question: 'Les données de ma revente sont elles intégrables dans mon logiciel de data ?',
    score: 1,
    answer: 'Nous sommes déjà connectés <span class="fw-700">avec de nombreux logiciels de data</span> français, ce qui vous permet de centraliser les données de votre tableau de bord directement dans ces logiciels.<br>Toutes les données sont également <span class="fw-700">exportables</span> au format CSV.',
    children: [],
  },
  whoOwnsData: {
    id: 49,
    fragment: 'organisateur_proprietaire-donnees',
    question: 'Qui est propriétaire des données générées par nos bourses aux billets ?',
    score: 1,
    answer: `Les données générées par nos services <span class="fw-700">appartiennent aux organisateurs</span>. Notre usage se limite strictement à la création et à la gestion de votre bourse aux billets. Nous ne pourrons en aucun cas vendre, donner, louer ou utiliser vos données pour notre propre compte.
      <br><br>Par ailleurs, toutes nos données sont exclusivement <span class="fw-700">hébergées en France</span> et soumises à la <span class="fw-700">réglementation RGPD</span>.
      `,
    children: [],
  },
  myCgv: {
    id: 54,
    fragment: 'organisateur_cgv',
    question: 'Est-ce que je peux ajouter mes CGV sur Reelax Tickets ?',
    score: 0,
    answer: '<span class="fw-700">Oui</span>, il est indispensable que l\'ensemble de votre public puisse avoir accès à vos CGV. Pour cela, vous n\'avez rien à faire, on s\'occupe de tout !',
    children: [],
  },
  reelaxProvider: {
    id: 52,
    fragment: 'organisateur_politique-confidentialite',
    question: 'Est-ce que je dois ajouter Reelax Tickets comme prestataire dans ma politique de confidentialité ?',
    score: 0,
    answer: 'La mention n\'est pas obligatoire, mais <span class="fw-700">recommandée</span> afin d\'être le plus transparent possible avec votre public.',
    children: [],
  },

};
