import { Injectable, OnDestroy, inject } from '@angular/core';
import { Document } from 'flexsearch';
import { BehaviorSubject, Subscription } from 'rxjs';
import { faq as faqFR } from '../../assets/ressources/faq-fr';
import { faq as faqEN } from '../../assets/ressources/faq-en';
import { faq as faqES } from '../../assets/ressources/faq-es';
import { FAQItem, FaqSection, IFaq } from '../models';
import { Utils } from '../shared/utils';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class FaqSearchService implements OnDestroy {
  private translocoService = inject(TranslocoService);

  public isLoading = new BehaviorSubject<boolean>(true);

  // Unused
  public anwsersNb = 0;

  private index: Document<unknown, false>;
  private faq: IFaq;
  private oldFaq: {
    buyer: FAQItem;
    seller: FAQItem;
    organizer: FAQItem;
};
  private tagList;
  private debug = false;
  private translationSub: Subscription;

  constructor() {
    this.translationSub = this.translocoService.langChanges$.subscribe((lang) => this.init(lang));
  }

  private init(lang) {
    let langISO;
    if (lang.includes('en')) {
      this.faq = faqEN;
      langISO = 'EN';
    } else if (lang.includes('es')) {
      this.faq = faqES;
      langISO = 'ES';
    } else {
      this.faq = faqFR;
      langISO = 'FR';
    }
    this.oldFaq = this.buildOldFAQ(this.faq);

    this.tagList = Object.keys(this.oldFaq);
    this.index = new Document({
      charset: 'latin:advanced',
      language: langISO, // todo after faq translation set current language
      tokenize: 'forward', // allow partial word identification
      resolution: 5, // set contextual resolution (1-9, 9 matches more words)
      document: {
        id: 'id',
        tag: 'tag',
        index: ['question', 'answer', 'score'], // question key to search
      },
    });
    this.anwsersNb = 0;
    const startTime = new Date();
    Promise.all(this.tagList.map( (tag) => this.oldFaq[tag].map( (value) => {
      this.anwsersNb += 1;
      return this.index.addAsync(value.id, {
        id: value.id,
        tag,
        fragment: value.fragment,
        question: value.question,
        answer: value.answer,
        score: value.score?.toString(), // workaround flexsearch bug with latin charset
      });
    })).flat()).then( () => {
      if (this.debug) {
        // eslint-disable-next-line no-console
        console.log('done loading, loading time in ms: ', new Date().getTime() - startTime.getTime());
      }
      this.isLoading.next(false);
    });
  }

  public search(query: string, sections: FaqSection[]) {
    if (!this.index) {
      throw new Error('FaqSearch error : please init index first');
    }
    if (!query || query?.length <= 0) {
      return this.concatenateTags(sections); // no key word => returns all questions
    }
    return this.convertResultToFAQItems(
      this.index.search(query, {
        index: ['question', 'answer'],
        tag: sections,
      }),
      sections,
    );
  }

  public getItemByKey(key: string) {
    return this.faq?.[key];
  }

  private getLeaves(tree, rootName: string, result = []){
    if(tree?.[rootName]?.children?.length === 0){
      result.push(tree[rootName]);
    } else {
      tree?.[rootName]?.children?.forEach((childName: string) => {
        this.getLeaves(tree, childName, result);
      });
    }
    return result;
  }

  private removeDuplicates(array) {
    return array?.filter((obj, index) => index === array?.findIndex((o) => obj.id === o.id));
  }

  private buildOldFAQ(newFAQ) {
    return {
      buyer: this.removeDuplicates(this.getLeaves(newFAQ, 'buyer')),
      seller: this.removeDuplicates(this.getLeaves(newFAQ, 'seller')),
      organizer: this.removeDuplicates(this.getLeaves(newFAQ, 'organizer')),
    };
  }

  public getMostSearched(sections: FaqSection[]) {
    return this.convertResultToFAQItems(
      this.index.search('1', {
        index: ['score'],
        tag: sections,
      }),
      sections,
    );
  }

  private convertResultToFAQItems(results: any[], tags: string[]) {
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.log(results);
    }
    return Utils.uniqBy(
      results.map( (results) => this.concatenateTags(tags)
        .filter((q) => results.result.includes(q.id))).flat(), (r) => r.id,
    );
  }

  public getParentName(faqItem: FAQItem): string {
    const key = Object.keys(this.faq).find((k) => faqFR[k].id === faqItem.id);
    if (!key) {
      return '';
    }
    const parentItem = Object.values(this.faq).find((item) => item.children.includes(key));
    return parentItem?.name as string;
  }

  private concatenateTags(tagList = this.tagList): FAQItem[] {
    return tagList.map( (tag) => this.oldFaq[tag]).flat();
  }

  ngOnDestroy() {
    this.translationSub?.unsubscribe();
  }

}
