import { Component, Input, OnDestroy, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';
import { isPlatformBrowser, NgClass, UpperCasePipe } from '@angular/common';
import { FAQItem, FaqSection, IFaq } from '../../../models';
import { debounceTime, delay, first, Subscription, tap } from 'rxjs';
import { FaqSearchService } from '../../../services';
import { HelpStore } from '../../../stores';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { TextFormFieldComponent } from '../inputs/text-form-field/text-form-field.component';
import { LoaderComponent } from '../loader/loader.component';
import { TrackingClickDirective } from '../../directives/tracking-click.directive';
import { MatIcon } from '@angular/material/icon';
import { TrackingInputDirective } from '../../directives/tracking-input.directive';

interface ExtendedFAQItem extends FAQItem {
  parentName?: string;
}

interface SearchForm {
  filter: FormControl<string>
}

@Component({
  selector: 'app-faq-content',
  templateUrl: './faq-content.component.html',
  styleUrls: ['./faq-content.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    TextFormFieldComponent,
    LoaderComponent,
    NgClass,
    TrackingClickDirective,
    TrackingInputDirective,
    MatIcon,
    UpperCasePipe,
  ],
})
export class FaqContentComponent implements OnInit, OnDestroy {
  helpStore = inject(HelpStore);
  faqSearchService = inject(FaqSearchService);
  translocoSertvice = inject(TranslocoService);

  @Input() set faqSections(faqSections: FaqSection[]) {
    // eslint-disable-next-line no-underscore-dangle
    this._faqSections = faqSections;
    this.faqForm.controls?.filter?.setValue(''); // triggers searchSub on faqSection change in order to reset questions and filter
  }
  get faqSections() {
    // eslint-disable-next-line no-underscore-dangle
    return this._faqSections;
  }
  private _faqSections: FaqSection[];
  @Input() set fragment(fragment: string) {
    if (fragment) {
      this.goToQuestion(fragment);
    }
  }
  /**
  * Unused
  */
  @Input() displayFrequent = false;

  public filteredItems: ExtendedFAQItem[];
  public displayedQuestions = new Array<string>();
  public lastOpenedFragment: string;
  public faqForm = new FormGroup({} as unknown as SearchForm);
  public isLoading = true;
  private isBrowser = false;

  private searchSub: Subscription;
  private searchLoadingSub: Subscription;

  constructor() {
    const platformId = inject(PLATFORM_ID);

    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.searchLoadingSub = this.faqSearchService.isLoading.subscribe( (loading) => {
      if (loading) {
        this.faqForm.disable();
      } else {
        this.isLoading = false;
        this.faqForm.enable();
      }
    });
    this.searchSub = this.faqForm.valueChanges.pipe(
      tap( () => this.isLoading = true),
      debounceTime(300),
      tap(({filter}) => {
        this.helpStore.loadFaqSearch(filter);
        if (!filter && this.displayFrequent) {
          this.filteredItems = this.faqSearchService.getMostSearched(this.faqSections);
        } else {
          this.filteredItems = this.faqSearchService.search(filter, this.faqSections);
        }
        this.filteredItems.forEach((e) => e.parentName = this.faqSearchService.getParentName(e) as string );
        this.filteredItems = this.filteredItems.sort((a,b) =>
          a.parentName < b.parentName
            ? -1
            : a.parentName > b.parentName
              ? 1 :
              b.score - a.score,
        );
        this.isLoading = false;
      }),
    ).subscribe();
  }

  getRelated(relatedKey: string) {
    return this.faqSearchService.getItemByKey(relatedKey);
  }

  getRelatedRoute(relatedKey: string) {
    const related = this.getRelated(relatedKey);
    const section = related.fragment?.split('_')?.[0];
    return `faq/${section}#${related.fragment}`;
  }

  private goToQuestion(fragment) {
    if (this.isBrowser) {
      this.faqSearchService.isLoading.pipe(
        first((loading) => !loading),
        delay(100),
      ).subscribe( () => {
        this.faqSearchService.search(undefined, Object.values(FaqSection)).forEach((item) => {
          if (item.fragment === fragment) {
            this.displayAnswer(item.fragment);
            setTimeout(() => {
              const el = document.getElementById(fragment);
              // new elements may have been loaded above so we adjust scroll
              el?.scrollIntoView({behavior: 'smooth'});
              this.lastOpenedFragment = item.fragment;
            }, 250);
          }
        });
      });
    }
  }

  protected switchAnswerDisplay(answerFragment: string) {
    const matchingIndex = this.displayedQuestions.indexOf(answerFragment);
    if (matchingIndex > -1) {
      this.displayedQuestions.splice(matchingIndex, 1);
      this.lastOpenedFragment = undefined;
    } else {
      this.displayedQuestions.push(answerFragment);
      this.lastOpenedFragment = answerFragment;
    }
  }

  private displayAnswer(answerFragment: string) {
    if (!this.displayedQuestions.includes(answerFragment)) {
      this.displayedQuestions.push(answerFragment);
    }
  }

  ngOnDestroy() {
    this.searchSub?.unsubscribe();
    this.searchLoadingSub?.unsubscribe();
  }

}
