import { Injectable, inject } from '@angular/core';
import { first, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Event, Organizer } from '../models';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrganizersService {
  private http = inject(HttpClient);

  public getSomeEvents = new Subject<{limit: number, offset: number, searchQuery: string, sort?: 'popularity' | 'date'}>();

  private configUrl = environment.apiUrl;

  getOrganizerByUrl(organizerUrl: string): Observable<Organizer> {
    const route = `${this.configUrl}o/n/${organizerUrl}`;
    return this.http.get<Organizer>(route).pipe(
      first(),
    );
  }

  getPaginatedEventsByOrganizerUrl(organizerUrl: string) {
    const route = `${this.configUrl}e/o/${organizerUrl}`;
    return this.getSomeEvents.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(300),
      // ignore new term if same as previous term
      distinctUntilChanged(),
      // switch to new search observable each time the term changes
      switchMap( ({limit, offset, searchQuery, sort}) => {
        const params = new HttpParams({
          fromObject: {
            limit: limit.toString(),
            offset: offset.toString(),
            searchQuery: encodeURIComponent(searchQuery.toString()),
            sort,
          },
        });
        return this.http.get<{rows: Event[], count: number}>(route, {params});
      }),
      map( (res) => ({
        ...res,
        rows: res.rows.map( (e) => new Event(e)),
      })),
    );
  }

}
