<app-card
  [data]="{
    title: event.name,
    content: event.PostalAddress?.id ? event.PostalAddress.shortAddress : event.location,
    imgUrl: event.imgUrl,
    url: (oldWidget ? '/w/e/' : '/e/n/') + event.url,
    date: event.dateStart,
    secondDate: event.dateEnd
  }"
/>
