import { Component, Input, ElementRef, ViewChild, OnDestroy, inject } from '@angular/core';
import { Event, Language, OrganizerAccessRight } from '../../../models';
import { ColorService } from '../../../services';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-event-cover',
  templateUrl: './event-cover.component.html',
  styleUrls: ['./event-cover.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    RouterLink,
  ],
})
export class EventCoverComponent implements OnDestroy {
  private colorService = inject(ColorService);
  private translocoService = inject(TranslocoService);

  @Input() set event(event: Event) {
    if (event) {
      // eslint-disable-next-line no-underscore-dangle
      this._event = event;
      this.onEvent();
    }
  }
  public get event() {
    // eslint-disable-next-line no-underscore-dangle
    return this._event;
  }
  private _event: Event;
  private langSub: Subscription;

  public currentLang: Language;

  public organizerRoute: string[];

  isLoadingEventInfo = true;
  partnerName: string;

  @ViewChild('backgroundImg', { static: true }) backgroundImg: ElementRef;

  constructor() {
    this.langSub = this.translocoService.langChanges$.subscribe((lang: Language) => {
      this.currentLang = lang;
    });
  }

  private onEvent() {
    if (this.event) {
      const tmpPartnerName = this.event.organizerName || this.event.name || ' ';
      // we set uppercase the first letter of organizer name
      this.partnerName = tmpPartnerName[0].toUpperCase() + tmpPartnerName.slice(1);
    }
    this.setBackgroundImage();
    this.setEventColor();
    this.organizerRoute = this.getOrgaRoute();
    this.isLoadingEventInfo = false;
  }

  ngOnDestroy() {
    this.colorService.resetEventColor();
    this.langSub?.unsubscribe();
  }

  setBackgroundImage() {
    if (!this.event) {
      return;
    }
    if (this.event && this.event.imgUrl && this.event.imgUrl !== '') {
      this.backgroundImg.nativeElement.style.backgroundImage = 'url(\'' + this.event.imgUrl + '\')';
    } else {
      this.backgroundImg.nativeElement.style.backgroundImage = 'url(\'assets/img/default-event-cover.jpg\')';
    }
  }

  setEventColor() {
    if (this.event && this.event.isPartner && this.event.color && this.event.color !== '') {
      this.colorService.setEventColor(this.event);
    }
  }

  getOrgaRoute(): string[] {
    const organizers = this.event?.Organizers;
    if (organizers?.length) {
      const owner = organizers.find((o) => o.EventOrganizer?.accessRight === OrganizerAccessRight.Owner && o.url);
      if (owner) {
        return ['/o', owner.url];
      } else if (organizers[0].url) {
        return ['/o', organizers[0].url];
      }
    }
    return ['/e', 'n', this.event.url ];
  }

}
