import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, inject } from '@angular/core';
import { debounceTime, filter, fromEvent, map, Subscription } from 'rxjs';
import { TrackingService } from '../../services';
import { MatomoEvent } from '../../models';

@Directive({
  selector: '[appTrackInput]',
  standalone: true,
})
export class TrackingInputDirective implements AfterViewInit, OnDestroy {
  private trackingService = inject(TrackingService);
  private inputObservable: Subscription;
  private count = 0;

  constructor(private element: ElementRef) {}

  /**
   * @param {MatomoEvent} event - The matomo event information
   */
  @Input('appTrackInput') event: MatomoEvent;

  ngAfterViewInit() {
    this.inputObservable = fromEvent(this.element.nativeElement, 'input')
      .pipe(
        map( (e) => e as InputEvent), // Type Assertions instead of type cast : fromEvent type cast will break in rxjs8
        filter( (e) => e.isComposing === false), // filter some event
        debounceTime(2000), // important debounce time because we want to wait the user has stopped typing
      )
      .subscribe({
        next: () => {
          this.count += 1;
          this.trackingService.addEvent({
            ...this.event,
            value: this.count,
            name: this.event.name,
          });
        },
      });
  }

  ngOnDestroy(): void {
    this.inputObservable?.unsubscribe();
  }

}
