<div class="dialog" *transloco="let t">

  <div class="dialog-header">
    <h1 class="dialog-title">🛟 {{ t('help.help') | titlecase }}</h1>
    <mat-icon class="show-on-extra-small-only close-icon" (click)="onCloseClick()">highlight_off</mat-icon>
  </div>

  <div class="dialog-content">

    @for (archiveNode of archives; track archiveNode; let i = $index) {
      @if (archiveNode?.name) {
        <mat-card class="fs-14 fw-600 my-3 right-message custom-event-bg">
          <mat-card-content>
            <span [innerHTML]="archiveNode.name | translateLanguageMap:currentLanguage" class="custom-event-text"></span>
          </mat-card-content>
        </mat-card>
      }
      @if (archiveNode?.question) {
        <mat-card class="fs-14 my-3 left-message">
          <mat-card-content>
            <span [innerHTML]="archiveNode.question | translateLanguageMap:currentLanguage"></span>
          </mat-card-content>
        </mat-card>
      }
      <div class="buttons-group">
        @for (archiveBtn of archiveNode?.children; track archiveBtn) {
          @if (!buttonsClicked.includes(archiveBtn) && canDisplay(archiveBtn) && customOrOriginalData(archiveBtn)?.name) {
            <button mat-button class="fs-14 button --x-small reelax-white-bg" (click)="changeResponse(i, archiveBtn)"
              [appTrackClick]="{category: 'faq', action: 'open-tree', name: archiveBtn, value: 1}">
              {{ customOrOriginalData(archiveBtn)?.name | translateLanguageMap:currentLanguage }}
            </button>
          }
        }
      </div>
    }

    @if (currentTreeNode?.name) {
      <mat-card class="fs-14 fw-600 my-3 right-message custom-event-bg">
        <mat-card-content>
          <span [innerHTML]="currentTreeNode.name | translateLanguageMap:currentLanguage" class="custom-event-text"></span>
        </mat-card-content>
      </mat-card>
    }
    @if (!isWaiting && currentTreeNode?.question) {
      <mat-card class="fs-14 my-3 left-message">
        <mat-card-content>
          <span [innerHTML]="currentTreeNode.question | translateLanguageMap:currentLanguage"></span>
        </mat-card-content>
      </mat-card>
    }

    <div class="buttons-group">
      @for (child of currentTreeNode?.children; track child) {
        @if (faq?.[child]?.children?.length > 0) {
          @if (!isWaiting && canDisplay(child) && customOrOriginalData(child)?.name) {
            <button
              mat-button
              class="button --x-small reelax-white-bg fs-14"
              (click)="openNextTreeNode(child, faq[child])"
              [appTrackClick]="{category: 'faq', action: 'open-tree', name: child, value: 1}"
              >
              {{ customOrOriginalData(child)?.name | translateLanguageMap:currentLanguage}}
            </button>
          }
        } @else {
          @if (!isWaiting && canDisplay(child) && customOrOriginalData(child)?.question) {
            <mat-accordion #panel class="left-message --full-width fs-14">
              <mat-expansion-panel
                (opened)="scrollTopOfThis(panel); lastQuestionOpened = faq[child]?.fragment"
                [expanded]="currentTreeNode?.children?.length === 1"
                [appTrackClick]="{category: 'faq', action: 'open-tree-question', name: faq[child]?.fragment, value: 1}"
                >
                <mat-expansion-panel-header>
                  <mat-panel-title class="fw-600">
                    {{ customOrOriginalData(child)?.question | translateLanguageMap:currentLanguage}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <span [innerHTML]="customOrOriginalData(child)?.answer | translateLanguageMap:currentLanguage"></span>
                @if (customOrOriginalData(child)?.related?.length > 0) {
                  <div class="mt-5">
                    <p class="fw-700">
                      {{t(customOrOriginalData(child)?.related?.length > 1 ? 'help.relatedQuestions' : 'help.relatedQuestion')}}
                    </p>
                    @for (related of customOrOriginalData(child)?.related; track related) {
                      - <a
                        [href]="getRelatedRoute(related)"
                        class="link --primary"
                        target="_blank"
                      >
                        {{ getRelated(related).question}}
                      </a>
                      <br>
                    }
                  </div>
                }
              </mat-expansion-panel>
            </mat-accordion>
          }
        }
      }
    </div>
    @if (isWaiting) {
      <mat-card class="my-3 left-message fs-20">
        <mat-card-content>
          <span class="dot">.</span>
          <span class="dot">.</span>
          <span class="dot">.</span>
        </mat-card-content>
      </mat-card>
    }
    @if (showfeedback && !isWaiting && faq?.[currentTreeNode?.children[0]]?.children?.length < 1) {
      <div class="mt-5">
        <mat-card class="left-message fs-14 fw-600">
          <mat-card-content>
            {{ t('faq.dialog.satisfiedQuestion') }}
          </mat-card-content>
        </mat-card>
        <div class="buttons-group">
          <button mat-button class="button --x-small reelax-white-bg fs-14"
            (click)="satisfied()"
            [appTrackClick]="{category: 'faq', action: 'is-satisfied', name: lastQuestionOpened, value: 1}"
            >
            {{ t('faq.dialog.yes') }}
          </button>
          <button mat-button class="button --x-small reelax-white-bg fs-14"
            (click)="unsatisfied()"
            [appTrackClick]="{category: 'faq', action: 'is-not-satisfied', name: lastQuestionOpened, value: 1}"
            >
            {{ t('faq.dialog.no') }}
          </button>
        </div>
        @if (isSatisfied !== undefined) {
          <mat-card class="my-3 right-message fw-600 fs-14 custom-event-bg">
            <mat-card-content>
              <span class="custom-event-text">{{ isSatisfied ? t('faq.dialog.yes'): t('faq.dialog.no') }}</span>
            </mat-card-content>
          </mat-card>
        }
        @if (isSatisfied !== undefined && !isSatisfied) {
          <mat-card class="my-4 left-message --full-width fs-14">
            <mat-card-content>
              <div class="mb-4">
                <p>{{ t('faq.dialog.twoOptions') }}</p>
                <p>- <a class="link --primary" [routerLink]="ROUTES.FAQ.BASE">{{ t('faq.dialog.seeFaq') }}</a></p>
                <p>- {{ t('faq.dialog.contactSupport') }}</p>
              </div>
              <app-contact-form
                textSize="fs-14"
                [hasCustomColor]="true"
                [withMailBox]="false"
              />
            </mat-card-content>
          </mat-card>
        }
        @if (isSatisfied !== undefined && isSatisfied) {
          <mat-card class="my-4 left-message fs-14">
            <mat-card-content>
              <p class="fw-600">{{ t('faq.dialog.perfect') }}</p>
              <p class="mt-4">
                {{ t('faq.dialog.otherAnswers') }} <a class="link --primary" [routerLink]="ROUTES.FAQ.BASE">{{ t('faq.dialog.ourFaq') }}</a>
              </p>
            </mat-card-content>
          </mat-card>
        }
      </div>
    }

    <div #scrollContainer class="mt-3"></div>
  </div>

</div>
