// eslint-disable-next-line max-classes-per-file
import { Utils } from '../shared/utils';
import { Currencies, getCurrencySymbol } from './currencies.model';
import { priceFormatter, sellingPricesFormatter, percentageFormatter } from './formatter';
import { CategoriesGroup, LanguageMap, Platforms, defaultSeatingInformations } from './index';

export interface PlatformCategories {
  [k:string]: {
    platformName: string;
    eventId: number;
    categoryId?: string;
    preventEdition?: boolean;
  }
}

export const defaultCategory: Partial<Category> = {
  available: true,
  currency: Currencies.Euro,
  minimalTotalFees: 0,
  feesCoefficient: 1000,
  feesFlat: 0,
  organizerFeesFlat: 0,
  organizerFeesShare: 0,
  upgradeReelaxFees: 3000,
};

const categoryToDuplicate = (c: Category): Partial<Category> => ({
  available: c.available,
  expiryDate: c.expiryDate,
  description: c.description,
  eventId: c.eventId,
  currency: c.currency,
  minimalTotalFees: c.minimalTotalFees,
  feesCoefficient: c.feesCoefficient,
  feesFlat: c.feesFlat,
  organizerFeesShare: c.organizerFeesShare,
  organizerFeesFlat: c.organizerFeesFlat,
  ticketTemplateName: c.ticketTemplateName,
  ticketTemplateId: c.ticketTemplateId,
  displayedSeatingInformation: c.displayedSeatingInformation,
  CategoriesGroup: c.CategoriesGroup,
});

export class Category {
  available: boolean;
  capacity: number;
  /**
   * @description dateStart renamed to expiryDate in oct. 2024
   */
  expiryDate: Date;
  description: LanguageMap = {};
  descriptionDisplay: boolean;
  createdAt: Date;
  updatedAt: Date;
  eventId: number;
  name: string;
  price: number;
  currency: Currencies;
  minimalSalePrice: number;
  maximalSalePrice: number;
  minimalTotalFees: number;
  feesCoefficient: number;
  feesFlat: number;
  organizerFeesShare: number;
  organizerFeesFlat: number;
  order: number;
  ticketTemplateName: string;
  ticketTemplateId: number;
  displayedSeatingInformation: {[key: string]: boolean};
  id?: number;
  platformCategoryId?: string;
  platformsForeignIds: {[key: string]: string};
  upgradeCategoryId: number;
  upgradeReelaxFees: number;
  upgradeFeeName1: string; // optionnal, default "Frais billetterie"
  upgradeFeeName2: string; // optionnal, default "Frais organisateur"
  categoriesGroupId: number;
  CategoriesGroup: CategoriesGroup;
  modifiedBy: number;

  constructor(options?: Partial<Category>) {
    if (options) {
      this.available = options.available;
      this.createdAt = options.createdAt ? new Date(options.createdAt) : null;
      this.updatedAt = options.updatedAt ? new Date(options.updatedAt) : null;
      this.capacity = options.capacity;
      this.expiryDate = options.expiryDate ? new Date(options.expiryDate) : null;
      this.description = options.description ?? {};
      this.descriptionDisplay = options.descriptionDisplay;
      this.eventId = options.eventId;
      this.name = options.name;
      this.price = options.price;
      this.minimalSalePrice = options.minimalSalePrice;
      this.maximalSalePrice = options.maximalSalePrice;
      this.minimalTotalFees = options.minimalTotalFees;
      this.feesCoefficient = options.feesCoefficient;
      this.feesFlat = options.feesFlat;
      this.organizerFeesShare = options.organizerFeesShare;
      this.organizerFeesFlat = options.organizerFeesFlat;
      this.order = options.order;
      this.ticketTemplateName = options.ticketTemplateName;
      this.ticketTemplateId = options.ticketTemplateId;
      this.id = options.id;
      this.currency = options.currency;
      this.platformCategoryId = options.platformCategoryId;
      this.platformsForeignIds = options.platformsForeignIds ?? {};
      this.CategoriesGroup = options.CategoriesGroup;
      this.categoriesGroupId = options.categoriesGroupId;
      this.displayedSeatingInformation = options.displayedSeatingInformation ?? defaultSeatingInformations;
      this.upgradeCategoryId = options.upgradeCategoryId;
      this.upgradeReelaxFees = options.upgradeReelaxFees;
      this.upgradeFeeName1 = options.upgradeFeeName1;
      this.upgradeFeeName2 = options.upgradeFeeName2;
    }
  }

  get humanReadableFaceValue(): string {
    return priceFormatter([this.price]);
  }

  get humanReadableMaximalPriceWithFees(): string {
    return priceFormatter([
      Math.max(
        Utils.minimalPriceWithFees(this, this.maximalSalePrice),
        Utils.defaultPriceWithFees(this, this.maximalSalePrice),
      ),
    ]);
  }

  get humanReadableSellingPrices(): string {
    return sellingPricesFormatter(this.minimalSalePrice, this.maximalSalePrice, this.currency);
  }

  get humanReadableFees(): string {
    const minimalFeesString = (this.minimalTotalFees <= 0) ? undefined : (priceFormatter([this.minimalTotalFees ?? 0]) + ' ' + this.currencySymbol);
    const defaultFeesString = [
      (this.feesCoefficient <= 0) ? undefined : percentageFormatter(this.feesCoefficient),
      (this.feesFlat <= 0) ? undefined : (priceFormatter([this.feesFlat ?? 0]) + ' ' + this.currencySymbol),
    ].filter( (p) => !!p).join(' + ');
    if (
      !minimalFeesString ||
      Utils.minimalPriceWithFees(this, this.minimalSalePrice) < Utils.defaultPriceWithFees (this, this.minimalSalePrice)
    ) {
      // if no minimalPriceWithFees or unreachable
      return defaultFeesString;
    } else if (
      !defaultFeesString ||
      Utils.defaultPriceWithFees(this, this.maximalSalePrice) < Utils.minimalPriceWithFees(this, this.maximalSalePrice)
    ) {
      // if no defaultPriceWithFees or unreachable
      return minimalFeesString;
    }
    return [
      minimalFeesString,
      defaultFeesString,
    ].filter( (p) => !!p).join(' OU ');
  }

  get currencySymbol() {
    return getCurrencySymbol(this.currency);
  }

  public get categoryToDuplicate() {
    return categoryToDuplicate(this);
  }

  hasErrors(eventPlatformWithForeignIds: Platforms[]) {
    const properties = [
      'name',
    ];
    const intProperties = [
      'order',
      'price',
      'minimalSalePrice',
      'maximalSalePrice',
      'feesCoefficient',
    ];
    const platformPropertiesError = eventPlatformWithForeignIds
      .map( (p) => (this.platformsForeignIds[p] && this.platformsForeignIds[p].length >= 1) ?
        undefined :
        `platformsForeignIds ${p}`);
    const priceErrors = [
      this.minimalSalePrice < 0 ? 'minimalSalePrice' : undefined,
      this.maximalSalePrice < 0 ? 'maximalSalePrice' : undefined,
      this.minimalSalePrice > this.maximalSalePrice ? 'min > max' : undefined,
    ];
    const upgradeErrors = [
      this.upgradeCategoryId && !this.upgradeReelaxFees ? 'upgradeConfig' : undefined,
    ];
    return [
      ...properties.filter( (p) => !this[p]),
      ...intProperties.filter( (p) => !Number.isInteger(this[p])),
      ...platformPropertiesError.filter( (p) => !!p),
      ...priceErrors.filter( (p) => !!p),
      ...upgradeErrors.filter( (p) => !!p),
    ];
  }
}

export class CategoryStat extends Category {
  public averageSellingPrice: number;
  public soldTicketsNb: number;
  public onSaleTicketsNb: number;
  public stillActiveAutoPurchase: number;
  // Category
  available: boolean;
  capacity: number;
  name: string;
  price: number;
  minimalSalePrice: number;
  maximalSalePrice: number;
  feesCoefficient: number;
  minimalTotalFees: number;
  feesFlat: number;
  organizerFeesShare: number;
  organizerFeesFlat: number;
  order: number;
  ticketTemplateName: string;
  ticketTemplateId: number;
  id?: number;

  constructor(options?: any) {
    super(options);
    this.averageSellingPrice = options.averageSellingPrice; // only sold tickets
    this.soldTicketsNb = options.soldTicketsNb;
    this.onSaleTicketsNb = options.onSaleTicketsNb;
    this.stillActiveAutoPurchase = options.stillActiveAutoPurchase;
  }

  get humanReadableAveragePrice(): string {
    const formattedValue = priceFormatter([this.averageSellingPrice]);
    return formattedValue !== 'NaN' ? formattedValue : null;
  }
}
