<ng-container *appLet="(appService.settings.asObservable() | async) as setting">
  <div class="px-3 small-container" [ngClass]="{'bg-confetti-animated': isBirthday}" *transloco="let tav; read 'accountValidation'">
    <h1 class="subtitle --center mt-5">
      @if ((userRole && userRole !== 'anonymous')) {
        {{ tav(isBirthday ? 'happyBirthday' : 'hello', { firstname }) }}
      } @else {
        @if (isLogin) {
          {{ isPasswordForgotten? tav('resetYourPassword') : tav('login')}}
        } @else {
          {{ tav('registration') }}
        }
        <ng-template #login>
          {{ isPasswordForgotten? tav('resetYourPassword') : tav('login')}}
        </ng-template>
        <ng-template #registration>
          {{ tav('registration') }}
        </ng-template>
      }
      <ng-template #logged>
        {{ tav(isBirthday ? 'happyBirthday' : 'hello', { firstname }) }}
      </ng-template>
    </h1>

    <mat-card class="mt-4 mb-5">
      <div class="center">
        <!-- classic login form -->
        <mat-card-content>
          @if (isLogin && !isNewPasswordMailSent && (!userRole || userRole === 'anonymous')) {
            <form [formGroup]="credentialsForm" (ngSubmit)="onSubmitLogin()">
              <div class="form --centered">
                <mat-form-field class="form__field mb-4" subscriptSizing="dynamic">
                  <mat-label>{{tav('email')}}</mat-label>
                  <input [appTrim]="true" formControlName="email" name="email" matInput type="email" email required>
                  @if (!isPasswordForgotten) {
                    <mat-hint>{{ tav('weWillSendYourTicketInfoHere') }}</mat-hint>
                  }
                  @if (isPasswordForgotten) {
                    <mat-hint>{{ tav('pleaseEnterTheAddressYouProvided') }}</mat-hint>
                  }
                  @if (getError(credentialsForm, 'email')) {
                    <mat-error>{{ 'formErrors.' + getError(credentialsForm, 'email') | transloco }}</mat-error>
                  }
                </mat-form-field>
                @if (!isPasswordForgotten) {
                  <mat-form-field class="form__field mb-4">
                    <mat-label>{{tav('password')}}</mat-label>
                    <input formControlName="password" name="password" matInput [type]="hidePassword1 ? 'password' : 'text'" required>
                    <button type="button" mat-icon-button matSuffix (click)="hidePassword1 = !hidePassword1">
                      <mat-icon>{{hidePassword1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    @if (getError(credentialsForm, 'password')) {
                      <mat-error>{{ 'formErrors.' + getError(credentialsForm, 'password') | transloco }}</mat-error>
                    }
                  </mat-form-field>
                }
              </div>
              @if (isLoadingAuth) {
                <app-loader diameter="40" class="m-auto"/>
              }
              @if (!!formError && ((getError(credentialsForm, 'password') && !isPasswordForgotten) || getError(credentialsForm, 'email'))) {
                <p class="error fs-16 center">{{ 'formErrors.' + formError | transloco }}</p>
              }
              @if (!((getError(credentialsForm, 'password') && !isPasswordForgotten) || getError(credentialsForm, 'email')) && serverErrors?.length > 0) {
                <p class="error fs-16 center">
                  @for (error of serverErrors; track error; let last = $last) {
                    {{ 'formErrors.' + error | transloco }}
                    @if (!last) {
                      <br>
                      }
                    }
                  </p>
                }
                <div class="buttons-group --row --centered">
                  <button mat-button class="button --primary --full-width --small" type="submit" [disabled]="isLoadingAuth">
                    {{ (isPasswordForgotten? 'button.confirm' : 'button.logIn' ) | transloco}}
                  </button>
                </div>
                <a class="mx-auto pt-4 fs-14 link --primary" (click)="onForgetPasswordClick()">{{isPasswordForgotten? tav('backToTheLoginForm') : tav('forgotYourPassword')}}</a>
                <div class="py-4">
                  <mat-divider class="m-0" [inset]="true"/>
                </div>
                @if (!isPasswordForgotten) {
                  <p class="fs-16">{{ tav('dontHaveAnAccount') }}</p>
                }
                <div class="buttons-group --row --centered">
                  <button mat-button class="button --outlined-primary --full-width --small" (click)="switchLogin()" type="button" [disabled]="isLoadingAuth">
                    {{ tav('signUp') }}
                  </button>
                </div>
              </form>
            }

            <!-- registration form if user not logged in or if user is anonymous -->
            @if (!isLogin && (!userRole || userRole === 'anonymous')) {
              @if (setting.disabledEmailVerification) {
                <p class="text">
                  <mat-icon [style.verticalAlign]="'bottom'">warning</mat-icon>
                  {{ tav('checkEmailSpelling') }}
                </p>
              }
              <form [formGroup]="registerForm" (ngSubmit)="onSubmitRegister()">
                <div class="form --centered">
                  <mat-form-field class="form__field mb-4" subscriptSizing="dynamic">
                    <mat-label>{{tav('email')}}</mat-label>
                    <input [appTrim]="true" formControlName="email" matInput type="email" email required>
                    <mat-hint>{{ tav('weWillSendYourTicketInfoHere') }}</mat-hint>
                    @if (getError(registerForm, 'email')) {
                      <mat-error>{{ 'formErrors.' + getError(registerForm, 'email') | transloco }}</mat-error>
                    }
                  </mat-form-field>
                  <mat-form-field class="form__field mb-2">
                    <mat-label>{{tav('password')}}</mat-label>
                    <input formControlName="password" matInput [type]="hidePassword2 ? 'password' : 'text'" (input)="checkPasswordMatch()" required>
                    <button type="button" mat-icon-button matSuffix (click)="hidePassword2 = !hidePassword2">
                      <mat-icon>{{hidePassword2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    @if (getError(registerForm, 'password')) {
                      <mat-error>{{ 'formErrors.' + getError(registerForm, 'password') | transloco }}</mat-error>
                    }
                  </mat-form-field>
                  <mat-form-field class="form__field mb-2">
                    <mat-label>{{tav('passwordConfirmation')}}</mat-label>
                    <input formControlName="passwordConfirm" matInput  [type]="hideConfirmPassword ? 'password' : 'text'" (input)="checkPasswordMatch()" required>
                    <button type="button" mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">
                      <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    @if (getError(registerForm, 'passwordConfirm')) {
                      <mat-error>{{ 'formErrors.' + getError(registerForm, 'passwordConfirm') | transloco }}</mat-error>
                    }
                  </mat-form-field>
                  <app-password-strength class="full-width text-left mb-3" [form]="registerForm"/>
                  <div class="form__field mb-4" *transloco="let tai; read 'accountInfos'">
                    <mat-checkbox name="cgReelax" formControlName="cgReelax" color="primary" required><p class="checkbox-text">{{ tai('iAcceptThe') }} <a class="link --primary" href="https://reelax-tickets.com/assets/pdf/CGU_reelax.pdf?v=06012025" target="_blank">{{ tai('termsAndConditions') }}</a> {{ tai('andThe') }} <a class="link --primary" href="https://reelax-tickets.com/assets/pdf/PP_reelax.pdf" target="_blank">{{ tai('privacyPolicy') }}</a> {{ tai('ofReelaxTickets') }}.<span [matTooltip]="'formErrors.required' | transloco" class="custom-event">&nbsp;*</span></p></mat-checkbox>
                    @if (getError(registerForm, 'cgReelax')) {
                      <mat-error class="fs-12">{{ 'formErrors.' + getError(registerForm, 'cgReelax') | transloco }}</mat-error>
                    }
                  </div>
                </div>
                @if (isLoadingAuth) {
                  <app-loader diameter="40" class="m-auto"/>
                }
                @if (!!formError && (!registerForm.valid || passwordStrengthComponent?.progressSecurisation < 80)) {
                  <p class="error fs-16 center">{{ 'formErrors.' + formError | transloco }}</p>
                }
                @if (registerForm.valid && serverErrors?.length > 0) {
                  <p class="error fs-16 center">
                    @for (error of serverErrors; track error; let last = $last) {
                      {{ 'formErrors.' + error | transloco }}
                      @if (!last) {
                        <br>
                        }
                      }
                    </p>
                  }
                  <div class="buttons-group --row --centered">
                    <button mat-button class="button --small --primary --full-width" type="submit" [disabled]="isLoadingAuth">
                      {{ 'button.signUp' | transloco }}
                    </button>
                  </div>
                  <div class="py-4">
                    <mat-divider class="m-0" [inset]="true"/>
                  </div>
                  <p class="fs-16">{{ tav('alreadyHaveAnAccount') }}</p>
                  <div class="buttons-group --row --centered">
                    <button mat-button class="button --small --outlined-primary --full-width" (click)="switchLogin()" type="button">
                      {{ tav('loginYourself') }}
                    </button>
                  </div>
                </form>
              }

              <!-- if user is logged in and not anonymous -->
              @if (userRole && userRole !== 'anonymous') {
                <p class="fs-16 py-5">
                  {{ tav('youAreWellConnected') }}
                </p>
                <div class="buttons-group --row --centered">
                  @if (!setting?.isWidget) {
                    <button mat-button class="button --outlined-primary --small mb-3" [routerLink]="ROUTES.EVENTS">
                      {{ 'button.events' | transloco }}
                    </button>
                  }
                  <button mat-button class="button --primary --small mb-3" [routerLink]="ROUTES.ACCOUNT.BASE">
                    <mat-icon class="button__icon">account_circle</mat-icon> {{ 'button.myAccount' | transloco }}
                  </button>
                  @if (userRole && userRole === 'organizer') {
                    <button [routerLink]="ROUTES.ORGA"
                      mat-button class="button --primary --small mb-3">
                      <mat-icon class="button__icon">insert_chart</mat-icon> {{ 'button.myDashboard' | transloco }}
                    </button>
                  }
                  @if (userRole && userRole === 'admin') {
                    <button [routerLink]="ROUTES.ADMIN"
                      mat-button class="button --primary --small mb-3">
                      <mat-icon class="button__icon">dashboard</mat-icon> {{ 'button.myDashboard' | transloco }}
                    </button>
                  }
                </div>
              }

              <!-- new password email sent -->
              @if (isNewPasswordMailSent) {
                <p class="fs-16 pb-5">
                  {{ tav('ifAnAccountMatches') }}@if (email) {
                  <span> ({{email}})</span>
                  }, {{ tav('youWillReceiveAnEmail') }}<br>
                  {{ tav('pleaseClickOnTheLink') }}
                </p>
                <a class="link --primary fs-16" (click)="goBackToLogin()">
                  {{ tav('backToTheLoginPage') }}
                </a>
              }
            </mat-card-content>

          </div>
        </mat-card>

        <!-- information on login or registration page -->
        @if ((userRole === 'anonymous') && !isPasswordForgotten && !isNewPasswordMailSent && !this.specificProcess) {
          <h2 class="d-flex center"><mat-icon class="mr-3 custom-event">person</mat-icon> {{ tav('reelaxTicketsAccount') }}</h2>
          <mat-card ngClass="mt-4 custom-border-left center fs-16 mb-5">
            <mat-card-content>
              <p class="fs-16 pb-5">
                {{ tav('youCanBuyWithoutAnyAccount') }}<br>
                {{ tav('createAnAccountToSellOrGoFaster') }}
              </p>
              @if (!environment.production) {
                <a class="link --danger" (click)="logout()">
                  {{ 'button.logOut' | transloco }}
                </a>
              }
              @if (!setting?.isWidget) {
                <div class="buttons-group --row --centered">
                  <a [routerLink]="ROUTES.EVENTS">
                    <button mat-button class="button --outlined-primary">
                      {{ 'button.seeAvailableEvents' | transloco }}
                    </button>
                  </a>
                </div>
              }
            </mat-card-content>
          </mat-card>
        }

        </div>

      </ng-container>
