import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ProcessType } from '../../models';
import { AppService, AuthService, SEOService } from '../../services';
import { PasswordCheckValidator, passwordMatchValidator } from '../../validators';
import { PasswordStrengthComponent } from '../../shared/components/password-strength/password-strength.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { LetDirective } from '../../shared/directives/let.directive';
import { AsyncPipe } from '@angular/common';
import { SafePipe } from '../../pipes/safe.pipe';
import { ROUTES } from '../../routes.constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    LoaderComponent,
    MatCard,
    MatCardContent,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatIconButton,
    MatSuffix,
    MatIcon,
    MatError,
    PasswordStrengthComponent,
    MatButton,
    RouterLink,
    LetDirective,
    AsyncPipe,
    SafePipe,
  ],
})
export class ResetPasswordComponent implements OnInit {
  appService = inject(AppService);
  private route = inject(ActivatedRoute);
  private seoService = inject(SEOService);
  private authService = inject(AuthService);
  private PasswordCheckValidator = inject(PasswordCheckValidator);

  ROUTES = ROUTES;
  passwordMatchValidator = passwordMatchValidator;
  processType = ProcessType;
  token: string;
  userAccountId: number;
  isUserVerified = false;
  isLoadingVerifying = true;
  isRegenerateDisabled = false;
  password: string;
  isTokenValid = false;
  isPasswordUpdated = false;
  isLoadingPasswordUpdate = false;
  error = '';
  hidePassword = true;
  hideConfirmPassword = true;
  specificProcess: ProcessType;
  @ViewChild(PasswordStrengthComponent) passwordStrengthComponent: PasswordStrengthComponent;

  public resetPasswordForm = new UntypedFormGroup({
    password: new UntypedFormControl('', {
      validators: [Validators.required, Validators.minLength(8)],
      asyncValidators: [this.PasswordCheckValidator.validate.bind(this.PasswordCheckValidator)],
    }),
    passwordConfirm: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
  }, { validators: passwordMatchValidator });

  constructor() {
    this.route.params.subscribe( (params) => {
      this.token = params.token;
    });
    this.route.queryParams.subscribe((params) => {
      if (params.isSellingProcess) {
        // legacy params was isSellingProcess so we maintain it for user clicking on old emails
        this.specificProcess = ProcessType.Selling;
      }
      this.specificProcess = params.specificProcess;
      this.userAccountId = params.userAccountId;
    });
    this.seoService.updateTitle('Réinitialisation de mot de passe');
  }


  getError(field: string) {
    const control = this.resetPasswordForm.get(field);
    if (!!control && !!control.errors) {
      return Object.keys(control.errors)[0];
    }
    return null;
  }

  ngOnInit() {
    this.checkTokenValidity();
  }

  checkPasswordMatch() {
    // if there is a noMatch error and we didn't add it to passwordConfirm control, then we do it
    if (!!this.resetPasswordForm.hasError('noMatch') &&
    !this.resetPasswordForm.get('passwordConfirm').hasError('noMatch')) {
      if (this.resetPasswordForm.get('passwordConfirm').errors) {
        this.resetPasswordForm.get('passwordConfirm').setErrors({
          ...this.resetPasswordForm.get('passwordConfirm').errors,
          noMatch: true,
        });
      } else {
        this.resetPasswordForm.get('passwordConfirm').setErrors({
          noMatch: true,
        });
      }
    } else if (!this.resetPasswordForm.hasError('noMatch') &&
                !!this.resetPasswordForm.get('passwordConfirm').hasError('noMatch')) {
      // if there is not any noMatch error anymore and we still store it into control errors, then we reset its errors
      this.resetPasswordForm.get('passwordConfirm').setErrors(null);
    }
  }

  checkTokenValidity() {
    this.authService.checkPasswordTokenValidity(this.userAccountId, this.token).subscribe( (res) => {
      if (res) {
        this.isTokenValid = true;
        this.isLoadingVerifying = false;
      } else {
        this.isTokenValid = false;
        this.isLoadingVerifying = false;
      }
    },
    (err) => {
      // eslint-disable-next-line no-console
      console.log('Error on checkTokenValidity function on reset-password component : ', err);
      this.isTokenValid = false;
      this.isLoadingVerifying = false;
    });
  }

  onRegenerateClick() {
    this.isRegenerateDisabled = true;
    this.authService.regeneratePasswordToken(this.userAccountId, this.token, this.specificProcess)
      .subscribe({
        next: (res) => {
        },
        error: (err) => {
          // eslint-disable-next-line no-console
          console.log('Error on onRegenerateClick function on reset-password component : ', err);
        },
      });
  }

  onSubmitResetPassword() {
    this.isLoadingPasswordUpdate = true;
    this.resetPasswordForm.markAsDirty();
    if (this.passwordStrengthComponent?.progressSecurisation < 80) {
      this.isLoadingPasswordUpdate = false;
      this.error = 'notStrongEnough';
      return;
    }
    if (!this.resetPasswordForm.valid) {
      this.isLoadingPasswordUpdate = false;
      this.error = 'invalidForm';
      return;
    }
    this.password = this.resetPasswordForm.get('password').value;
    this.authService.resetPassword(this.userAccountId, this.password, this.token)
      .subscribe({
        next: (res) => {
          this.isLoadingPasswordUpdate = false;
          if (res) {
            this.isPasswordUpdated = true;
            this.error = '';
          } else {
            this.isPasswordUpdated = true;
            this.error = 'serverError';
          }
        },
        error: (err) => {
          // eslint-disable-next-line no-console
          console.log('Error on onSubmitResetPassword function on reset-password component : ', err);
          this.isLoadingPasswordUpdate = false;
          this.error = 'serverError';
        },
      });
  }

}
