import { AbstractControl, ValidatorFn } from '@angular/forms';

export function minDateValidator(minDate: Date): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const controlDate = control?.value;
    if (!controlDate || !minDate) {
      return null;  // Don't validate empty value
    }
    return isChronological(minDate, controlDate) ? null : { minDate: true };
  };
}

export function maxDateValidator(maxDate: Date): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const controlDate = control?.value;
    if (!controlDate || !maxDate) {
      return null;  // Don't validate empty value
    }
    return isChronological(controlDate, maxDate) ? null : { maxDate: true };
  };
}

const isChronological = (minDate: Date, maxDate: Date): boolean => minDate <= maxDate;
