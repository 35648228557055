export const ROUTES = {
  BASE: '',
  ACCOUNT: {
    BASE: '/mon-compte',
    BANKACCOUNT: '/mon-compte/compte-bancaire',
    TICKETS: '/mon-compte/mes-billets',
    WAITING: '/mon-compte/mes-listes-d-attente',
    PERSO: '/mon-compte/informations-personnelles',
  },
  CONNECT: '/connexion',
  ORGA: '/orga',
  ADMIN: '/admin',
  PRO: '/pro',
  FAQ: {
    BASE: '/faq',
    BUYER: '/faq/acheteur',
    SELLER: '/faq/vendeur',
    ORGA: '/faq/organisateur',
  },
  EVENTS: '/evenements',
  DEMO: '/demo',
  LEGAL: '/mentions-legales',
  API: '/docs/api',
  CGU: '/assets/pdf/CGU_reelax.pdf?v=06012025',
  PRIVACY: '/assets/pdf/PP_reelax.pdf',
};

export const EXT_ROUTES = {
  FACEBOOK: 'https://www.facebook.com/reelax.tickets/',
  LINKEDIN: 'https://www.linkedin.com/company/reelax-tickets/',
  INSTAGRAM: 'https://www.instagram.com/reelaxtickets/',
};
